import { BaseModel, DataPartial } from 'contracts';
import { ToasterType } from '../../enums';
import { toasterConfigSchema } from './toaster-config.schema';
import { ObjectSchema } from 'yup';

export class ToasterConfig extends BaseModel {
  public title!: string;
  public content!: string;
  public destroyed!: boolean;
  public type?: ToasterType;
  public timeout?: number;
  public id?: string;

  constructor(data?: DataPartial<ToasterConfig>, skipValidation?: boolean) {
    super(data, skipValidation);
  }

  protected getDefaults(): DataPartial<BaseModel> {
    return {
      title: 'Title',
      content: 'Content placeholder',
      type: ToasterType.Default,
      timeout: 6000,
      destroyed: false,
      eventEmitter: null,
    };
  }

  protected getSchema(): ObjectSchema<any> {
    return toasterConfigSchema;
  }
}
