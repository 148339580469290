"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorResponseMock = void 0;
const api_error_1 = require("../api-error");
const error_response_model_1 = require("./error-response.model");
class ErrorResponseMock extends error_response_model_1.ErrorResponse {
    constructor(data) {
        const seedData = Object.assign({ timestamp: new Date(), statusCode: 400, sourceTag: 'MOCK_ERROR_TAG', request: 'GET /mock/request/url', errors: [new api_error_1.ApiErrorMock()] }, data);
        super(seedData);
    }
}
exports.ErrorResponseMock = ErrorResponseMock;
