import * as yup from 'yup';
import { ObjectSchema } from 'yup';
import { ToasterType } from '../../enums';
import { getEnumValues } from 'contracts';

export const toasterConfigSchema: ObjectSchema<any> = yup.object().shape({
  title: yup.string().required(),
  content: yup.string().required(),
  type: yup.string().oneOf(getEnumValues(ToasterType)).notRequired(),
  timeout: yup.number().notRequired(),
  id: yup.string().notRequired(),
  destroyed: yup.boolean().notRequired(),
});
