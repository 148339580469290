"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userSupportRequestSchema = void 0;
const yup = require("yup");
exports.userSupportRequestSchema = yup.object().shape({
    user: yup.object().required(),
    version: yup.object().required(),
    date: yup.number().required(),
    os: yup.string().required(),
    browser: yup.string().required(),
    problemDescription: yup.string().required(),
    relatedSquadron: yup.string().notRequired(),
});
