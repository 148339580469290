"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaginationAndFilterQuery = void 0;
const pagination_and_filter_query_schema_1 = require("./pagination-and-filter-query.schema");
const enums_1 = require("../../enums");
const base_model_1 = require("../base-model");
class PaginationAndFilterQuery extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {
            search: '',
            orderBy: enums_1.SquadronSortKeysType.Empty,
            orderByDirection: enums_1.SortFilterDirectionType.ASC,
            infiniteScrollRefId: '',
            limitResults: 25,
        };
    }
    getSchema() {
        return pagination_and_filter_query_schema_1.paginationAndFilterQuerySchema;
    }
}
exports.PaginationAndFilterQuery = PaginationAndFilterQuery;
