import { Injectable } from '@angular/core';
import { AuthProvider } from '../auth.provider';
// import { FirebaseStrategy } from '../strategies/firebase.strategy';
import { KeycloakStrategy } from '../strategies/keycloak.strategy';
import { environment } from 'src/environments/environment';
import { RestStrategy } from '../strategies/rest.strategy';
import { GameWardenStrategy } from '../strategies/gamewarden.strategy';

interface IStrategies {
  // firebase: FirebaseStrategy;
  keycloak: KeycloakStrategy;
  rest: RestStrategy;
  gamewarden: GameWardenStrategy;
}

@Injectable({
  providedIn: 'root',
})
export class AuthProviderHelper {
  public authProvider: AuthProvider;

  private strategies: IStrategies = {
    // firebase: this.firebaseStrategy,
    keycloak: this.keycloakStrategy,
    rest: this.restStrategy,
    gamewarden: this.gamewardenStrategy,
  };

  constructor(
    // private firebaseStrategy: FirebaseStrategy,
    private keycloakStrategy: KeycloakStrategy,
    private restStrategy: RestStrategy,
    private gamewardenStrategy: GameWardenStrategy
  ) {
    this.authProvider = AuthProvider.getInstance();
  }

  public initAuthProvider(): AuthProvider {
    const activeStrategy = this.strategies[environment.authProvider as keyof IStrategies];
    if (!this.authProvider.strategy || activeStrategy.name !== this.authProvider.strategy.name) {
      this.authProvider.useStrategy(activeStrategy ? activeStrategy : this.keycloakStrategy);
    }

    return this.authProvider;
  }
}
