"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Mds = void 0;
const mds_schema_1 = require("./mds.schema");
const base_model_1 = require("../base-model");
class Mds extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {};
    }
    getSchema() {
        return mds_schema_1.mdsSchema;
    }
}
exports.Mds = Mds;
