"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userEventRelationSchema = void 0;
const yup = require("yup");
exports.userEventRelationSchema = yup.object().shape({
    id: yup.string().notRequired(),
    userId: yup.string().required(),
    eventId: yup.string().required(),
    firstHalfDay: yup.number().positive().min(0).max(4),
    secondHalfDay: yup.number().positive().min(0).max(4),
    isApprovedToParticipateInEvent: yup.boolean().required(),
    isAwaitingVerification: yup.boolean().notRequired(),
    note: yup.string().notRequired(),
    userDutyPositionForEvent: yup.string().notRequired(),
    firstHalfDayType: yup.string().notRequired(),
    secondHalfDayType: yup.string().notRequired(),
    remarks: yup.array().of(yup.string()).notRequired(),
    availabilityId: yup.string().notRequired(),
    rank: yup.number().integer().moreThan(-1).notRequired(),
});
