"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Notification = void 0;
const notification_schema_1 = require("./notification.schema");
const notification_type_enum_1 = require("../../enums/notification-type.enum");
const notification_user_1 = require("./notification-user");
const base_model_1 = require("../base-model");
class Notification extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        var _a;
        super(data, skipValidation);
        this.createdAt = (data === null || data === void 0 ? void 0 : data.createdAt) || new Date().getTime();
        if ((_a = data === null || data === void 0 ? void 0 : data.actionLink) === null || _a === void 0 ? void 0 : _a.includes('acknowledge')) {
            this.type = notification_type_enum_1.NotificationType.Acknowledge;
            this.isAcknowledged = data.isAcknowledged || false;
        }
        if (!this.type) {
            this.type = notification_type_enum_1.NotificationType.Standard;
        }
        if (!(data === null || data === void 0 ? void 0 : data.sender)) {
            this.sender = new notification_user_1.NotificationUser({
                id: 'System',
                name: 'ProFlyt'
            });
        }
        if (!(data === null || data === void 0 ? void 0 : data.userList)) {
            this.userList = [];
        }
    }
    getDefaults() {
        return {};
    }
    getSchema() {
        return notification_schema_1.notificationSchema;
    }
}
exports.Notification = Notification;
