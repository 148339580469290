import { createFeatureSelector, createSelector } from '@ngrx/store';
import { alertStateFeatureKey, IAlertState } from '../reducers/alert-state.reducer';
import { ToasterConfig } from '../../core/models';
import { DialogConfig } from '../../core/models/dialog-config';

export const selectAlertState = createFeatureSelector<IAlertState>(alertStateFeatureKey);

export const selectActiveToasters = createSelector(selectAlertState, (alertState) => alertState.activeToasters);

export const selectToasterById = (id: string) =>
  createSelector(selectAlertState, (alertState) => {
    let finalToaster: ToasterConfig = null;

    alertState.activeToasters.map((toaster: ToasterConfig) => {
      if (toaster.id === id) {
        finalToaster = toaster;
      }
    });

    return finalToaster;
  });

export const selectActiveDialogs = createSelector(selectAlertState, (alertState) => alertState.activeDialogs);

export const selectDialogById = (id: string) =>
  createSelector(selectAlertState, (alertState) => {
    let finalDialog: DialogConfig = null;

    alertState.activeDialogs.map((dialog: DialogConfig) => {
      if (dialog.id === id) {
        finalDialog = dialog;
      }
    });

    return finalDialog;
  });
