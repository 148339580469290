"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MdsType = void 0;
var MdsType;
(function (MdsType) {
    MdsType["C17"] = "C-17";
    MdsType["HH60G"] = "HH-60G";
    MdsType["C130H"] = "C-130H";
    MdsType["KC135"] = "KC-135";
    MdsType["C130J"] = "C-130J";
    MdsType["AES"] = "AES";
    MdsType["MULTIPLE"] = "MULTIPLE";
    MdsType["C5"] = "C-5";
    MdsType["C37"] = "C-37";
    MdsType["MQ9"] = "MQ-9";
    MdsType["M125"] = "M125";
    MdsType["WC130J"] = "WC130J";
    MdsType["PA28RT201T"] = "PA-28RT-201T";
    MdsType["U2"] = "U-2";
})(MdsType = exports.MdsType || (exports.MdsType = {}));
