"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.eventMissionDetailSchema = void 0;
const yup = require("yup");
const arct_entity_schedule_1 = require("./entities/arct-entity/arct-entity.schedule");
const transition_entity_schedule_1 = require("./entities/transition-entity/transition-entity.schedule");
exports.eventMissionDetailSchema = yup.object().shape({
    id: yup.string().notRequired(),
    callSign: yup.string().notRequired(),
    missionNumber: yup.string().notRequired(),
    departure: yup.string().notRequired(),
    arrival: yup.string().notRequired(),
    transition: yup.array().of(transition_entity_schedule_1.transitionEntitySchema).nullable().notRequired(),
    arct: yup.array().of(arct_entity_schedule_1.arctEntitySchema).nullable().notRequired(),
    // Pending to define
    lowLevelInfo: yup.string().notRequired(),
    moaInfo: yup.string().notRequired(),
    tankerDetail: yup.string().matches(/^\d{1,5}$/, "Tanker DetailMust be only digits").notRequired(),
    receiverDetail: yup.string().notRequired(),
    arctInfo: yup.string().matches(/^\d{1,5}$/, "ARCT Info Must be only digits").notRequired(),
});
