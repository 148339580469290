"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.notificationSchema = void 0;
const yup = require("yup");
const notification_type_enum_1 = require("../../enums/notification-type.enum");
const utilities_1 = require("../../utilities");
exports.notificationSchema = yup.object().shape({
    id: yup.string().notRequired(),
    title: yup.string().required(),
    body: yup.string().required(),
    relatedUserId: yup.string().required(),
    actionLink: yup.string().notRequired().nullable(),
    createdAt: yup.number().notRequired().nullable(),
    type: yup.string().oneOf((0, utilities_1.getEnumValues)(notification_type_enum_1.NotificationType)).notRequired().nullable(),
    isAcknowledged: yup.boolean().notRequired().nullable(),
    sender: yup.object().shape({
        id: yup.string().notRequired().nullable(),
        name: yup.string().notRequired(),
        lastName: yup.string().notRequired().nullable(),
    }).notRequired(),
    userList: yup.array().of(yup.object().shape({
        id: yup.string().required(),
        name: yup.string().required(),
        lastName: yup.string().notRequired().nullable(),
    })).notRequired(),
});
