"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertToJSObject = void 0;
/**
 * Maps the key of an object, so turn classes into plain js objects
 * ex: { user: User { id: '12', type: UserType { number: 1 } } } --> { user: { id: '12', type: { number: 1 } } }
 */
const convertToJSObject = (object) => {
    return JSON.parse(JSON.stringify(object));
};
exports.convertToJSObject = convertToJSObject;
