"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pointOfContactSchema = void 0;
const yup = require("yup");
exports.pointOfContactSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().required(),
    phone: yup.number().notRequired(),
    aircrewType: yup.string().required(),
    dutyPosition: yup.string().notRequired(),
});
