import * as AlertActionTypes from '../actions/alert.actions';
import { createReducer, on } from '@ngrx/store';
import { ToasterConfig } from '../../core/models';
import { DialogConfig } from '../../core/models/dialog-config';

export const alertStateFeatureKey = 'alertState';

export interface IAlertState {
  activeToasters: ToasterConfig[];
  activeDialogs: DialogConfig[];
}

export const alertInitialState: IAlertState = {
  activeToasters: [],
  activeDialogs: [],
};

export const alertReducer = createReducer(
  alertInitialState,
  // Toaster
  on(AlertActionTypes.ClearAllToasters, (state) => {
    return { ...state, activeToasters: [] };
  }),
  on(AlertActionTypes.AddToaster, (state, action) => {
    return { ...state, activeToasters: [action.toaster, ...state.activeToasters] };
  }),
  on(AlertActionTypes.RemoveToaster, (state, action) => {
    return {
      ...state,
      activeToasters: state.activeToasters.filter((toaster) => toaster.id !== action.toasterID),
    };
  }),
  // Dialog
  on(AlertActionTypes.ClearAllDialogs, (state) => {
    return { ...state, activeDialogs: [] };
  }),
  on(AlertActionTypes.AddDialog, (state, action) => {
    return { ...state, activeDialogs: [...state.activeDialogs, action.dialog] };
  }),
  on(AlertActionTypes.RemoveDialog, (state, action) => {
    return {
      ...state,
      activeDialogs: state.activeDialogs.filter((dialog) => dialog.id !== action.dialogID),
    };
  })
);
