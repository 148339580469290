"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RendevousType = exports.FuelTrackingType = exports.RefuelingCapabilitiesType = exports.ComponentType = exports.ServiceBranchType = void 0;
var ServiceBranchType;
(function (ServiceBranchType) {
    ServiceBranchType["AirForce"] = "Air Force";
    ServiceBranchType["Army"] = "Army";
    ServiceBranchType["Navy"] = "Navy";
    ServiceBranchType["Marines"] = "Marines";
    ServiceBranchType["CoastGuard"] = "Coast Guard";
})(ServiceBranchType = exports.ServiceBranchType || (exports.ServiceBranchType = {}));
var ComponentType;
(function (ComponentType) {
    ComponentType["Reserves"] = "Reserves";
    ComponentType["ActiveDuty"] = "Active Duty";
    ComponentType["NationalGuard"] = "National Guard";
})(ComponentType = exports.ComponentType || (exports.ComponentType = {}));
var RefuelingCapabilitiesType;
(function (RefuelingCapabilitiesType) {
    RefuelingCapabilitiesType["Tanker"] = "Tanker";
    RefuelingCapabilitiesType["Receiver"] = "Receiver";
    RefuelingCapabilitiesType["Both"] = "Both";
    RefuelingCapabilitiesType["Neither"] = "Neither";
})(RefuelingCapabilitiesType = exports.RefuelingCapabilitiesType || (exports.RefuelingCapabilitiesType = {}));
var FuelTrackingType;
(function (FuelTrackingType) {
    FuelTrackingType["Klbs"] = "k lbs";
    FuelTrackingType["Gallons"] = "Gallons";
})(FuelTrackingType = exports.FuelTrackingType || (exports.FuelTrackingType = {}));
var RendevousType;
(function (RendevousType) {
    RendevousType["Enroute"] = "Enroute";
    RendevousType["PointParallel"] = "Point Parallel";
    RendevousType["Anchor"] = "Anchor";
    RendevousType["Overtaking"] = "Overtaking";
})(RendevousType = exports.RendevousType || (exports.RendevousType = {}));
