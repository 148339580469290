import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Platform } from '@ionic/angular';
import { DialogConfig } from '../../alert-system/core/models/dialog-config/dialog-config.model';
import { DialogType } from '../../alert-system/core/enums/dialog-type.enum';
import { IDialog } from '../../alert-system/core/interfaces/dialog-object.interface';
import { AlertSystemService } from '../../alert-system/alert-system.service';
import { DialogEvents } from '../../alert-system/core/enums/dialog-events.enum';
import { environment } from 'src/environments/environment';
import { InterceptorIgnoreHeader } from '../interceptors/auth-interceptor';
import { Store } from '@ngrx/store';
import {
  setIsMayorVersionUpdateNeeded,
  setIsMinorVersionUpdateNeeded,
} from '../../proflyt-root/store-root/actions/root.actions';
import { IApplicationVersions } from 'contracts';

@Injectable({
  providedIn: 'root',
})
export class UpdateAppVersionService {
  private appIdentifier = environment.appIdentifier;
  private iosAppUrl = `itms-apps://itunes.apple.com/app/${environment.iosAppId}`;
  private androidAppUrl = `https://play.google.com/store/apps/details?id=${this.appIdentifier}`;

  constructor(
    private http: HttpClient,
    private appVersion: AppVersion,
    private inAppBrowser: InAppBrowser,
    private platform: Platform,
    private store: Store,
    private alertSystemService: AlertSystemService
  ) {}

  public checkForUpdate() {
    this.http
      .get<IApplicationVersions>(`${environment.apiBaseUrl}/version`, {
        headers: {
          [InterceptorIgnoreHeader]: '',
        },
      })
      .subscribe({
        next: async (apiResponse: IApplicationVersions) => {
          const actualAppVersion: string = await this.appVersion.getVersionNumber();
          const serverVersionSplitted: string[] = apiResponse.mobileVersion.split('.');
          const splittedVersion: string[] = actualAppVersion.split('.');
          const isMayorVersionUpdate: boolean = serverVersionSplitted[0] > splittedVersion[0];
          const isMinorOrPatchVersionUpdate: boolean =
            serverVersionSplitted[1] > splittedVersion[1] ||
            (serverVersionSplitted[1] === splittedVersion[1] && serverVersionSplitted[2] > splittedVersion[2]);

          if (isMayorVersionUpdate) {
            this.store.dispatch(setIsMayorVersionUpdateNeeded({ isMayorVersion: true }));

            return;
          }

          if (isMinorOrPatchVersionUpdate) {
            this.store.dispatch(setIsMinorVersionUpdateNeeded({ isMinorVersion: true }));
          }
        },
      });
  }

  public openAppStore() {
    if (this.platform.is('android')) {
      this.inAppBrowser.create(this.androidAppUrl);
    } else {
      this.inAppBrowser.create(this.iosAppUrl);
    }
  }

  public showAppUpdateDialog(isMayorVersion: boolean = false) {
    let dialogConfig: DialogConfig;

    if (isMayorVersion) {
      dialogConfig = new DialogConfig({
        title: 'New Update Available',
        content: 'Please Update ProFlyt app to the latest version',
        type: DialogType.Update,
        canBeDismissed: false,
        opaqueBackground: true,
      });
    } else {
      dialogConfig = new DialogConfig({
        title: 'App Update Available',
        content: 'There is a new app version available. Would you like to download it now?',
        type: DialogType.ConfirmCancel,
        canBeDismissed: true,
        opaqueBackground: true,
      });
    }

    const confirmDialog: IDialog = this.alertSystemService.showDialog(dialogConfig);
    confirmDialog.eventEmitter.subscribe({
      next: (event: DialogEvents) => {
        if (event === DialogEvents.ClickedAccept) {
          this.openAppStore();
        }

        confirmDialog.destroy();

        if (isMayorVersion) {
          this.store.dispatch(setIsMayorVersionUpdateNeeded({ isMayorVersion: false }));
        }

        this.store.dispatch(setIsMinorVersionUpdateNeeded({ isMinorVersion: false }));
      },
    });
  }
}
