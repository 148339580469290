"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItsModifications = void 0;
const its_modifications_schema_1 = require("./its-modifications.schema");
const base_model_1 = require("../base-model");
class ItsModifications extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {};
    }
    getSchema() {
        return its_modifications_schema_1.itsModificationsSchema;
    }
}
exports.ItsModifications = ItsModifications;
