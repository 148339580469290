"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ratingSchema = void 0;
const yup = require("yup");
exports.ratingSchema = yup.object().shape({
    user: yup.object().required(),
    hours: yup.number().required(),
    minutes: yup.number().required(),
    rating: yup.number().required(),
    message: yup.string().notRequired(),
    appVersion: yup.string().notRequired(),
    createdAt: yup.number().required(),
});
