import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, interval, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
// import { AuthService } from 'src/app/auth/services/auth.service';
import { take, switchMap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToasterType } from '../../alert-system/core/enums/toaster-type.enum';
import { AlertSystemService } from '../../alert-system/alert-system.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { LocalStorageKeys } from 'src/app/shared/enums';
import { ITokenSetCredentials } from 'src/app/auth/interfaces/token-set-credentials.interface';
import { AuthApiService } from 'src/app/auth/services/auth-api.service';
import { AuthProvider } from '../../auth/providers/auth.provider';
import { AuthProviderType } from 'contracts';
import { AuthProviderHelper } from 'src/app/auth/providers/core/auth-provider.helper';

export const InterceptorIgnoreHeader = 'X-Ignore-Interceptor';
export const defautToken = `Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ0dUszMzdMX1JOekMtTnVwbTBOX1lSM0F5UkpTdXBDVXVKamFUUlZPN2RFIn0.eyJleHAiOjE3MTc1OTk1MDksImlhdCI6MTcxNzU5OTIwOSwiYXV0aF90aW1lIjoxNzE3NTk4MTQyLCJqdGkiOiI3ZmJjNTBkMi0wOTgxLTQyZGEtYTJkNi0wNDJhNDk4ZWIxNWQiLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODEvcmVhbG1zL3Rlc3QiLCJhdWQiOiJhY2NvdW50Iiwic3ViIjoiMzljY2Q5ODEtZDAyNS00N2U3LTk1NTEtNDdhM2MzZWUyNDljIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoicHJvZmx5dC13ZWItYXBwLWNsaWVudCIsIm5vbmNlIjoiMzU1MmE1NWUtNDUzYS00MDYwLTk5ZTQtNDk3YjY4Njg2ZDkwIiwic2Vzc2lvbl9zdGF0ZSI6ImM4YjJmYjJjLTliMzMtNDkzYS1hODUzLTMwZGJmODQ4MWFmYiIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiaHR0cDovL2xvY2FsaG9zdDo0NjAwIl0sInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJkZWZhdWx0LXJvbGVzLXRlc3QiLCJvZmZsaW5lX2FjY2VzcyIsInVtYV9hdXRob3JpemF0aW9uIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19fSwic2NvcGUiOiJvcGVuaWQgZW1haWwgcHJvZmlsZSIsInNpZCI6ImM4YjJmYjJjLTliMzMtNDkzYS1hODUzLTMwZGJmODQ4MWFmYiIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJuYW1lIjoiU2hvb3BwYWxsIFN0b3JlIiwicHJlZmVycmVkX3VzZXJuYW1lIjoic2hvb3BwYWxsIiwiZ2l2ZW5fbmFtZSI6IlNob29wcGFsbCIsImZhbWlseV9uYW1lIjoiU3RvcmUiLCJlbWFpbCI6InNob29wcGFsbEBnbWFpbC5jb20ifQ.pXP2kUFLJW3E9Lse9yc0JvhP5ThECSsL27cui_HLn8tyLDH0UjsEW9HQtJVFIME4zvzNlID8uHnUoaVxamafLR5XPVmP0PzhADatvIXMgbVuJ6b3Jqm2e1PhG3HpLirZk7sm9IVw3YmrjcegVk-PSbzrBzhIAIjl5cdkBgNt2EVdR1e-US4rH0ZH1AlHPldEL_9B6mIgteVNUl8LagL157Zx8gOx-BcXRfpCpgF2sp8mfqMoen6ZpA9hpE7fP2xYjXSw0ZRsHxDV_GVYFPx2RFDiwOpFqulqQQvB6F7I1GupUpVZa-kh8QZplioVGjnw1Yptjp7MKJESloCiQB3uTw`;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private authProvider: AuthProvider;
  private isTokenToasterShowing = false;

  constructor(
    private router: Router,
    private alertSystemService: AlertSystemService,
    private storageService: StorageService,
    private authApiService: AuthApiService,
    private authProviderHelper: AuthProviderHelper,
  ) {
    this.initAuthProvider();
  }


  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authProvider?.strategy?.name === AuthProviderType.gamewarden) {
      return next.handle(req);
    }

    // Allow to ignore headers, in case of using 3d parties
    if (req.headers.has(InterceptorIgnoreHeader)) {
      const newHeaders = req.headers.delete(InterceptorIgnoreHeader);

      return next.handle(req.clone({ headers: newHeaders }));
    }

    if (this.authProvider.strategy.name === 'keycloak') {
      return next.handle(req);
    }

    const ignoreContentType = 'X-Ignore-ContentType';

    return this.storageService.getKey(LocalStorageKeys.TokenSet).pipe(
      take(1),
      switchMap((tokenSet: ITokenSetCredentials) => {
        if (!tokenSet) {
          this.router.navigate(['auth']);

          return next.handle(req);
        }

        let headers = new HttpHeaders().set('authorization', 'Bearer ' + tokenSet.accessToken);

        if (!req.headers.has(ignoreContentType)) {
          headers = headers.append('content-type', 'application/json');
        }

        const copiedReq = req.clone({ headers });

        if (this.isBlobRequest(req)) {
          const newHeaders = req.headers.delete('X-Expected-Response-Type');
          req = req.clone({ headers: newHeaders });

          return this.handleRefreshToken(tokenSet, req, next);
        }

        return next.handle(copiedReq).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status === 401 && error.error?.sourceTag === 'ERR_007') {
              return this.handleRefreshToken(tokenSet, req, next);
            }

            return throwError(() => error);
          })
        );
      })
    );
  }

  private handleRefreshToken(tokenSet: ITokenSetCredentials, req: HttpRequest<any>, next: HttpHandler) {
    return this.authApiService.refreshTokens(tokenSet.refreshToken).pipe(
      take(1),
      switchMap((newTokenSet: ITokenSetCredentials) => {
        this.storageService.setKey(LocalStorageKeys.TokenSet, newTokenSet).pipe(take(1));
        const newHeaders = req.headers.set('authorization', 'Bearer ' + newTokenSet.accessToken);
        const newReq = req.clone({ headers: newHeaders });

        return next.handle(newReq);
      }),
      catchError((error: HttpErrorResponse) => {
        console.log('AuthInterceptor/HttpErrorResponse ->', error);
        if (!this.isTokenToasterShowing) {
          this.isTokenToasterShowing = true;
          this.showTokenToaster(error);
        }

        return throwError(() => error);
      })
    );
  }

  private isBlobRequest(req: HttpRequest<any>): boolean {
    return req.headers.has('X-Expected-Response-Type') && req.headers.get('X-Expected-Response-Type') === 'blob';
  }

  private showTokenToaster(httpError: HttpErrorResponse) {
    this.alertSystemService.showToaster({
      title: httpError.error.errors[0].name,
      content: 'Please login again',
      type: ToasterType.Error,
    });

    // this.authService.logout();
    this.router.navigate(['auth']);

    interval(5000)
      .pipe(take(1))
      .subscribe(() => {
        this.isTokenToasterShowing = false;
      });
  }

  private initAuthProvider() {
    this.authProvider = AuthProvider.getInstance();

    if (!this.authProvider.strategy || !this.authProvider.strategy.name) {
      this.authProviderHelper.initAuthProvider();
    }
  }
}
