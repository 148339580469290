"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEnumValues = void 0;
function getEnumValues(enumType) {
    const rawValues = Object.values(enumType);
    let parsedValues;
    if (rawValues.every((v) => typeof v === 'string')) {
        parsedValues = rawValues;
    }
    else {
        parsedValues = rawValues.filter((v) => typeof v === 'number');
    }
    return parsedValues;
}
exports.getEnumValues = getEnumValues;
