"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiError = void 0;
const base_model_1 = require("../base-model");
const api_error_schema_1 = require("./api-error.schema");
class ApiError extends base_model_1.BaseModel {
    static fromValidationError(validationError) {
        const data = {
            messages: [...(validationError.errors || [])],
            name: validationError.path || ApiError.defaultErrorName,
            error: true,
        };
        return new ApiError(data);
    }
    constructor(data, skipValidation) {
        super(data, skipValidation);
        /** Add this error property for js type checking, without types
         * this way we can check: if(response.error) { ... }
         */
        this.error = true;
    }
    toString() {
        const messages = this.messages || [];
        return `${this.name}: ${messages.join(' | ')}`;
    }
    getSchema() {
        return api_error_schema_1.apiErrorSchema;
    }
    getDefaults() {
        return { name: ApiError.defaultErrorName, messages: [], error: true };
    }
}
exports.ApiError = ApiError;
ApiError.defaultErrorName = 'Error';
