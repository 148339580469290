"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserEventRelation = void 0;
const user_event_relation_schema_1 = require("./user-event-relation.schema");
const base_model_1 = require("../base-model");
class UserEventRelation extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
        if (!(data === null || data === void 0 ? void 0 : data.isAwaitingVerification)) {
            this.isAwaitingVerification = false;
        }
    }
    getDefaults() {
        return {
            isApprovedToParticipateInEvent: false,
            isAwaitingVerification: false,
        };
    }
    getSchema() {
        return user_event_relation_schema_1.userEventRelationSchema;
    }
}
exports.UserEventRelation = UserEventRelation;
