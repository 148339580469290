"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiErrorMock = void 0;
const api_error_model_1 = require("./api-error.model");
class ApiErrorMock extends api_error_model_1.ApiError {
    constructor(data) {
        const seedData = Object.assign({ name: 'Mock API Error name', messages: ['Mock API error message 1', 'Mock API error message 2'], error: true }, data);
        super(seedData);
    }
}
exports.ApiErrorMock = ApiErrorMock;
