"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserMetadataMock = void 0;
const user_metadata_model_1 = require("./user-metadata.model");
class UserMetadataMock extends user_metadata_model_1.UserMetadata {
    constructor(data) {
        const seedData = Object.assign({ note: 'This is a test note', firstLoginTime: 0, unreadNotificationsCount: 0 }, data);
        super(seedData);
    }
}
exports.UserMetadataMock = UserMetadataMock;
