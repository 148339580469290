"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AircrewSortKeysType = void 0;
var AircrewSortKeysType;
(function (AircrewSortKeysType) {
    // These are camel case because they are object properties and should be equals to a database fields
    AircrewSortKeysType["LastName"] = "lastName";
    AircrewSortKeysType["AircrewPositionType"] = "sortByAircrewPositionType";
    AircrewSortKeysType["MainSquadronName"] = "mainSquadronName";
})(AircrewSortKeysType = exports.AircrewSortKeysType || (exports.AircrewSortKeysType = {}));
