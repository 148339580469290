"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.eventMissionProfileSchema = void 0;
const yup = require("yup");
exports.eventMissionProfileSchema = yup.object().shape({
    id: yup.string().notRequired(),
    briefTime: yup.number().min(1577851200000).max(2524622400000).notRequired(),
    stepTime: yup.number().min(1577851200000).max(2524622400000).notRequired(),
    takeoffTime: yup.number().min(1577851200000).max(2524622400000).notRequired(),
    arctTime: yup.number().min(1577851200000).max(2524622400000).notRequired(),
    lowLevelTime: yup.number().min(1577851200000).max(2524622400000).notRequired(),
    moaTime: yup.number().min(1577851200000).max(2524622400000).notRequired(),
    transitionTime: yup.number().min(1577851200000).max(2524622400000).notRequired(),
    landTime: yup.number().min(1577851200000).max(2524622400000).notRequired(), // 2020, 2050
});
