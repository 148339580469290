"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeZoneType = void 0;
var TimeZoneType;
(function (TimeZoneType) {
    TimeZoneType["EST"] = "Eastern Standard Time (UTC-5)";
    TimeZoneType["CST"] = "Central Standard Time (UTC-5)";
    TimeZoneType["MST"] = "Mountain Standard Time (UTC-7)";
    TimeZoneType["PST"] = "Pacific Standard Time (UTC-8)";
    TimeZoneType["HST"] = "Hawaii Standard Time (UTC-10)";
    // Below new ones
    TimeZoneType["DST"] = "Dateline Standard Time (UTC-12)";
    TimeZoneType["U"] = "UTC-11 (UTC-11)";
    TimeZoneType["AKST"] = "Alaska Standard Time (UTC-8)";
    TimeZoneType["PDT"] = "Pacific Standard Time (Mexico) (UTC-7)";
    TimeZoneType["PDT0"] = "Pacific Daylight Time (UTC-7)";
    TimeZoneType["MDT"] = "Mountain Standard Time (Mexico) (UTC-6)";
    TimeZoneType["MDT0"] = "Mountain Standard Time (UTC-6)";
    TimeZoneType["CAST"] = "Central America Standard Time (UTC-6)";
    TimeZoneType["CDT0"] = "Central Standard Time (Mexico) (UTC-5)";
    TimeZoneType["CCST"] = "Canada Central Standard Time (UTC-6)";
    TimeZoneType["SPST"] = "SA Pacific Standard Time (UTC-5)";
    TimeZoneType["EDT"] = "Eastern Daylight Time (UTC-4)";
    TimeZoneType["UEDT"] = "US Eastern Standard Time (UTC-5)";
    TimeZoneType["VST"] = "Venezuela Standard Time (UTC-4.5)";
    TimeZoneType["PYT"] = "Paraguay Standard Time (UTC-4)";
    TimeZoneType["ADT"] = "Atlantic Standard Time (UTC-3)";
    TimeZoneType["CBST"] = "Central Brazilian Standard Time (UTC-4)";
    TimeZoneType["SWST"] = "SA Western Standard Time (UTC-4)";
    TimeZoneType["PSST"] = "Pacific SA Standard Time (UTC-4)";
    TimeZoneType["NDT"] = "Newfoundland Standard Time (UTC-2.5)";
    TimeZoneType["ESAST"] = "E. South America Standard Time (UTC-3)";
    TimeZoneType["AST"] = "Argentina Standard Time (UTC-3)";
    TimeZoneType["SEST"] = "SA Eastern Standard Time (UTC-3)";
    TimeZoneType["GDT"] = "Greenland Standard Time (UTC-3)";
    TimeZoneType["MST0"] = "Montevideo Standard Time (UTC-3)";
    TimeZoneType["BST"] = "Bahia Standard Time (UTC-3)";
    TimeZoneType["U0"] = "UTC-02 (UTC-2)";
    TimeZoneType["MDT00"] = "Mid-Atlantic Standard Time (UTC-1)";
    TimeZoneType["ADT0"] = "Azores Standard Time (UTC)";
    TimeZoneType["CVST"] = "Cape Verde Standard Time (UTC-1)";
    TimeZoneType["MDT000"] = "Morocco Standard Time (UTC+1)";
    TimeZoneType["UTC"] = "UTC (UTC)";
    TimeZoneType["GMT"] = "GMT Standard Time (UTC)";
    TimeZoneType["BST0"] = "British Summer Time (UTC+1)";
    TimeZoneType["GDT0"] = "GMT Standard Time (UTC+1)";
    TimeZoneType["GST"] = "Greenwich Standard Time (UTC)";
    TimeZoneType["WEDT"] = "W. Europe Standard Time (UTC+2)";
    TimeZoneType["CEDT"] = "Central Europe Standard Time (UTC+2)";
    TimeZoneType["RDT"] = "Romance Standard Time (UTC+2)";
    TimeZoneType["CEDT0"] = "Central European Standard Time (UTC+2)";
    TimeZoneType["WCAST"] = "W. Central Africa Standard Time (UTC+1)";
    TimeZoneType["NST"] = "Namibia Standard Time (UTC+1)";
    TimeZoneType["GDT00"] = "GTB Standard Time (UTC+3)";
    TimeZoneType["MEDT"] = "Middle East Standard Time (UTC+3)";
    TimeZoneType["EST0"] = "Egypt Standard Time (UTC+2)";
    TimeZoneType["SDT"] = "Syria Standard Time (UTC+3)";
    TimeZoneType["EEDT"] = "E. Europe Standard Time (UTC+3)";
    TimeZoneType["SAST"] = "South Africa Standard Time (UTC+2)";
    TimeZoneType["FDT"] = "FLE Standard Time (UTC+3)";
    TimeZoneType["TDT"] = "Turkey Standard Time (UTC+3)";
    TimeZoneType["JDT"] = "Israel Standard Time (UTC+3)";
    TimeZoneType["LST"] = "Libya Standard Time (UTC+2)";
    TimeZoneType["JST"] = "Jordan Standard Time (UTC+3)";
    TimeZoneType["AST0"] = "Arabic Standard Time (UTC+3)";
    TimeZoneType["KST"] = "Kaliningrad Standard Time (UTC+3)";
    TimeZoneType["AST00"] = "Arab Standard Time (UTC+3)";
    TimeZoneType["EAT"] = "Eastern Africa Time (UTC+3)";
    TimeZoneType["MSK"] = "Moscow Standard Time (UTC+3)";
    TimeZoneType["SAMT"] = "Samara Time (UTC+4)";
    TimeZoneType["IDT"] = "Iran Standard Time (UTC+4.5)";
    TimeZoneType["AST000"] = "Arabian Standard Time (UTC+4)";
    TimeZoneType["ADT00"] = "Azerbaijan Standard Time (UTC+5)";
    TimeZoneType["MST00"] = "Mauritius Standard Time (UTC+4)";
    TimeZoneType["GET"] = "Georgian Standard Time (UTC+4)";
    TimeZoneType["AST0000"] = "Afghanistan Standard Time (UTC+4.5)";
    TimeZoneType["WAST"] = "West Asia Standard Time (UTC+5)";
    TimeZoneType["YEKT"] = "Yekaterinburg Time (UTC+5)";
    TimeZoneType["PKT"] = "Pakistan Standard Time (UTC+5)";
    TimeZoneType["IST"] = "India Standard Time (UTC+5.5)";
    TimeZoneType["SLST"] = "Sri Lanka Standard Time (UTC+5.5)";
    TimeZoneType["NST0"] = "Nepal Standard Time (UTC+5.75)";
    TimeZoneType["CAST0"] = "Central Asia Standard Time (UTC+6)";
    TimeZoneType["BST00"] = "Bangladesh Standard Time (UTC+6)";
    TimeZoneType["MST000"] = "Myanmar Standard Time (UTC+6.5)";
    TimeZoneType["SAST0"] = "SE Asia Standard Time (UTC+7)";
    TimeZoneType["NCAST"] = "N. Central Asia Standard Time (UTC+7)";
    TimeZoneType["CST0"] = "China Standard Time (UTC+8)";
    TimeZoneType["NAST"] = "North Asia Standard Time (UTC+8)";
    TimeZoneType["MPST"] = "Singapore Standard Time (UTC+8)";
    TimeZoneType["WAST0"] = "W. Australia Standard Time (UTC+8)";
    TimeZoneType["TST"] = "Taipei Standard Time (UTC+8)";
    TimeZoneType["UST"] = "Ulaanbaatar Standard Time (UTC+8)";
    TimeZoneType["NAEST"] = "North Asia East Standard Time (UTC+8)";
    TimeZoneType["JST0"] = "Japan Standard Time (UTC+9)";
    TimeZoneType["KST0"] = "Korea Standard Time (UTC+9)";
    TimeZoneType["CAST00"] = "Cen. Australia Standard Time (UTC+9.5)";
    TimeZoneType["ACST"] = "AUS Central Standard Time (UTC+9.5)";
    TimeZoneType["EAST"] = "E. Australia Standard Time (UTC+10)";
    TimeZoneType["AEST"] = "AUS Eastern Standard Time (UTC+10)";
    TimeZoneType["WPST"] = "West Pacific Standard Time (UTC+10)";
    TimeZoneType["TST0"] = "Tasmania Standard Time (UTC+10)";
    TimeZoneType["YST"] = "Yakutsk Standard Time (UTC+9)";
    TimeZoneType["CPST"] = "Central Pacific Standard Time (UTC+11)";
    TimeZoneType["VST0"] = "Vladivostok Standard Time (UTC+11)";
    TimeZoneType["NZST"] = "New Zealand Standard Time (UTC+12)";
    TimeZoneType["U00"] = "UTC+12 (UTC+12)";
    TimeZoneType["FST"] = "Fiji Standard Time (UTC+12)";
    TimeZoneType["MST0000"] = "Magadan Standard Time (UTC+12)";
    TimeZoneType["KDT"] = "Kamchatka Standard Time (UTC+13)";
    TimeZoneType["TST00"] = "Tonga Standard Time (UTC+13)";
    TimeZoneType["SST"] = "Samoa Standard Time (UTC+13)";
})(TimeZoneType = exports.TimeZoneType || (exports.TimeZoneType = {}));
