"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventFormation = void 0;
const _1 = require(".");
const base_model_1 = require("../../base-model");
class EventFormation extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {};
    }
    getSchema() {
        return _1.eventFormationSchema;
    }
}
exports.EventFormation = EventFormation;
