"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AircrewPositionType = void 0;
var AircrewPositionType;
(function (AircrewPositionType) {
    AircrewPositionType["Navigator"] = "Navigator";
    AircrewPositionType["External"] = "External";
    AircrewPositionType["Pilot"] = "Pilot";
    AircrewPositionType["AeTech"] = "AE Tech";
    AircrewPositionType["AerialGunner"] = "Aerial Gunner";
    AircrewPositionType["Admin"] = "Admin";
    AircrewPositionType["FlightEngineer_X2"] = "Flight Engineer (x2)";
    AircrewPositionType["Intel_IOS"] = "Intel (IOS)";
    AircrewPositionType["SensorOperator"] = "Sensor Operator";
    AircrewPositionType["Aviator"] = "Aviator";
    AircrewPositionType["FlightAttendant"] = "Flight Attendant";
    AircrewPositionType["Pilot_Single_Seat"] = "Pilot (single seat)";
    AircrewPositionType["SpecialMissionAviator"] = "Special Mission Aviator";
    AircrewPositionType["Aikwo"] = "AIKWO";
    AircrewPositionType["FlightSurgeon"] = "Flight Surgeon";
    AircrewPositionType["Loadmaster_X2"] = "Loadmaster (x2)";
    AircrewPositionType["CommSystemOperator"] = "Comm System Operator";
    AircrewPositionType["Pilot_UAV"] = "Pilot (UAV)";
    AircrewPositionType["Maintenance"] = "Maintenance";
    AircrewPositionType["CivPilot"] = "Civ Pilot";
    AircrewPositionType["Loadmaster"] = "Loadmaster";
    AircrewPositionType["Intel_MIC"] = "Intel (MIC)";
    AircrewPositionType["FlightNurse"] = "Flight Nurse";
    AircrewPositionType["Boom"] = "Boom";
    AircrewPositionType["EWD_DSD"] = " EWD/DSD";
})(AircrewPositionType = exports.AircrewPositionType || (exports.AircrewPositionType = {}));
