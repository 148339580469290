"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SquadronSortKeysType = void 0;
var SquadronSortKeysType;
(function (SquadronSortKeysType) {
    // This values are in lowercase because they should be equals to a database fields
    SquadronSortKeysType["Name"] = "name";
    SquadronSortKeysType["MDS"] = "mds";
    SquadronSortKeysType["SortByPocName"] = "SortByPocName";
    SquadronSortKeysType["Status"] = "status";
    SquadronSortKeysType["CreatedAt"] = "createdAt";
    SquadronSortKeysType["Empty"] = "";
})(SquadronSortKeysType = exports.SquadronSortKeysType || (exports.SquadronSortKeysType = {}));
