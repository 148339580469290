"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.kebabToPascalCase = void 0;
function kebabToPascalCase(text) {
    return text.replace(/(^\w|-\w)/g, clearAndUpper);
}
exports.kebabToPascalCase = kebabToPascalCase;
function clearAndUpper(text) {
    return text.replace(/-/, '').toUpperCase();
}
