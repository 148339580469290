"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.errorResponseSchema = void 0;
const yup = require("yup");
const api_error_1 = require("../api-error");
exports.errorResponseSchema = yup.object().shape({
    errors: yup.array().of(api_error_1.apiErrorSchema).min(1),
    request: yup.string(),
    statusCode: yup.number().min(400).max(599).required(),
    sourceTag: yup.string().required(),
    timestamp: yup.date().required(),
});
