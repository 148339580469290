"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.regexStringSchema = void 0;
const yup = require("yup");
/**
 * When we want to have a string that is a certain regex
 * @param regex: The regular expression we want to validate against.
 * @param opts: Required if we want to make that string required.
 */
function regexStringSchema(regex, opts) {
    const setOptions = Object.assign({ required: false }, opts);
    const base = yup.string().matches(regex);
    return setOptions.required ? base.required() : base;
}
exports.regexStringSchema = regexStringSchema;
