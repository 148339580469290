"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserMock = void 0;
const user_model_1 = require("./user.model");
const enums_1 = require("../../enums");
const aircrew_type_1 = require("../aircrew-type");
const user_metadata_1 = require("./user-metadata");
const user_config_time_zone_enum_1 = require("../../enums/user-config-time-zone.enum");
class UserMock extends user_model_1.User {
    constructor(data) {
        const seedData = Object.assign({ _id: '^&STEVC*SCU', squadronIds: ['5WF38na2tqsSHpDtJ2du', 'kAPgEF60NfI6q3Nw7SC2'], firstName: 'Jessie', lastName: 'Valladares', email: 'shajessie@hotmail.com', password: 'Lalalala123!', role: enums_1.RoleType.AircrewMember, specificationRoles: [], aircrewPosition: new aircrew_type_1.AircrewType({
                type: enums_1.AircrewPositionType.External,
                dutyPositions: [],
            }), joinedAt: new Date().getTime(), invitedAt: new Date().getTime(), phone: 5555555566, ftl: enums_1.FtlType.A, mds: enums_1.MdsType.C17, metadata: new user_metadata_1.UserMetadataMock({ note: 'Test note' }), enableApp: true, mct: false, nmr: false, searchKeys: ['a', 'ab', 'abc', 'abc1'], timezone: '-0500', isPublicUser: false, mainSquadronId: '5WF38na2tqsSHpDtJ2du', mainSquadronName: 'Delta', isBiometricAuthEnabled: false, devicesAcceptingPushNotifications: [
                'wqa38na2tqwesHpDtJ2du_xza38na2tqcdertpDtJ2du',
                'wqa38na2tqwesHpDtJ2du_qwerTy19872AsdERTfY_kjhbv2FRTy345MNBG',
            ], defaultTimezone: user_config_time_zone_enum_1.UserConfigTimeZoneType.Squadron }, data);
        super(seedData);
    }
}
exports.UserMock = UserMock;
