"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transitionEntitySchema = void 0;
const yup = require("yup");
const utilities_1 = require("../../../../../utilities");
exports.transitionEntitySchema = yup.object().shape({
    id: yup.string().notRequired(),
    location: yup.string().notRequired(),
    startTime: yup
        .string()
        .nullable()
        .notRequired()
        .test('validate-duration', 'Show time should be between 00:00 and 23:00', utilities_1.validateOtherTimes),
    endTime: yup
        .string()
        .nullable()
        .notRequired()
        .test('validate-duration', 'Show time should be between 00:00 and 23:00', utilities_1.validateOtherTimes),
});
