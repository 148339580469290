"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SarmCodes = void 0;
const base_model_1 = require("../base-model");
const sarm_codes_schema_1 = require("./sarm-codes.schema");
class SarmCodes extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {};
    }
    getSchema() {
        return sarm_codes_schema_1.sarmCodesSchema;
    }
}
exports.SarmCodes = SarmCodes;
