"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventId = void 0;
const event_id_schema_1 = require("./event-id.schema");
const base_model_1 = require("../base-model");
class EventId extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {};
    }
    getSchema() {
        return event_id_schema_1.eventIdSchema;
    }
}
exports.EventId = EventId;
