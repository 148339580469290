import * as yup from 'yup';
import { ObjectSchema } from 'yup';
import { DialogType, DialogSeverity } from '../../enums';
import { getEnumValues } from 'contracts';

export const dialogConfigSchema: ObjectSchema<any> = yup.object().shape({
  title: yup.string().required(),
  content: yup.string().required(),
  type: yup.string().oneOf(getEnumValues(DialogType)).notRequired(),
  timeout: yup.number().notRequired(),
  id: yup.string().notRequired(),
  destroyed: yup.boolean().notRequired(),
  opaqueBackground: yup.boolean().notRequired(),
  canBeDismissed: yup.boolean().notRequired(),
  severity: yup.string().oneOf(getEnumValues(DialogSeverity)).notRequired(),
});
