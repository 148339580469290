"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.asyncMap = void 0;
// This works as a map, but async, so you can use async/await and not execute one by one
const asyncMap = async (mapFunctionCode, arrayToMap) => {
    const promiseCollection = arrayToMap.map(async (element) => {
        return await mapFunctionCode(element);
    });
    return await Promise.all(promiseCollection);
};
exports.asyncMap = asyncMap;
