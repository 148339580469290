"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationUser = void 0;
const notification_user_schema_1 = require("./notification-user.schema");
const base_model_1 = require("../../base-model");
class NotificationUser extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {
            id: 'System',
            name: 'ProFlyt',
        };
    }
    getSchema() {
        return notification_user_schema_1.notificationUserSchema;
    }
}
exports.NotificationUser = NotificationUser;
