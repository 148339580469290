"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FailedPushNotifications = void 0;
const failed_push_notifications_schema_1 = require("./failed-push-notifications.schema");
const base_model_1 = require("../base-model");
class FailedPushNotifications extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {};
    }
    getSchema() {
        return failed_push_notifications_schema_1.failedPushNotificationsSchema;
    }
}
exports.FailedPushNotifications = FailedPushNotifications;
