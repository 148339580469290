"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.eventIdSchema = void 0;
const yup = require("yup");
const enums_1 = require("../../enums");
const utilities_1 = require("../../utilities");
exports.eventIdSchema = yup.object().shape({
    name: yup.string().required().max(15),
    type: yup.string().required().oneOf((0, utilities_1.getEnumValues)(enums_1.CalendarEventTypes)),
    id: yup.string().notRequired(),
    squadronId: yup.string().required(),
    duration: yup
        .string()
        .test('validate-duration', 'Duration should be between 00:00 and 23:00', utilities_1.validateDuration)
        .required(),
    notes: yup.string().notRequired(),
    showTime: yup
        .string()
        .nullable()
        .test('validate-duration', 'Show time should be between 00:00 and 23:00', utilities_1.validateOtherTimes)
        .notRequired(),
    briefTime: yup
        .string()
        .nullable()
        .test('validate-duration', 'Brief time should be between 00:00 and 23:00', utilities_1.validateOtherTimes)
        .notRequired(),
    stepTime: yup
        .string()
        .nullable()
        .test('validate-duration', 'Step time should be between 00:00 and 23:00', utilities_1.validateOtherTimes)
        .notRequired(),
});
