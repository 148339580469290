"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MdsMock = void 0;
const mds_model_1 = require("./mds.model");
class MdsMock extends mds_model_1.Mds {
    constructor(data) {
        const seedData = {
            id: '3yDrTDXM8BCfDMjLiADr',
            name: 'C-17',
        };
        super(seedData);
    }
}
exports.MdsMock = MdsMock;
