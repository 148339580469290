import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class SecurityHeadersInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const cloneRequest = request.clone({
      setHeaders: {
        'Content-Security-Policy': "default-src 'self'; script-src 'self'; object-src 'none';",
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'DENY',
        'Cache-Control': 'no-store, no-cache, must-revalidate, proxy-revalidate',
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains; preload'
      }
    });
    return next.handle(cloneRequest);
  }
}
