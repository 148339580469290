"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseSearchFilters = void 0;
function parseSearchFilters(params) {
    if (!params) {
        return new Array();
    }
    return params.map(filter => {
        const field = filter.field;
        const value = filter.value;
        const operator = filter.operator;
        return new Map([
            ['field', field],
            ['operator', operator],
            ['value', value]
        ]);
    });
}
exports.parseSearchFilters = parseSearchFilters;
