import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PrivacyViewComponent } from './privacy/privacy-view/privacy-view.component';

const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./proflyt-root/proflyt-root.module').then((m) => m.ProflytRootModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'privacy-policy',
    component: PrivacyViewComponent,
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
