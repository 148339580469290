"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Its = void 0;
const its_schema_1 = require("./its.schema");
const its_modifications_model_1 = require("../its-modifications/its-modifications.model");
const base_model_1 = require("../base-model");
class Its extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
        const actualDate = new Date();
        this.createdAt = actualDate.getTime();
        if (!this.updatedAt) {
            this.updatedAt = actualDate.getTime();
        }
        if (!this.userModifications) {
            this.userModifications = new its_modifications_model_1.ItsModifications({
                table: this.table,
                modifications: []
            });
        }
    }
    getDefaults() {
        return {};
    }
    getSchema() {
        return its_schema_1.itsSchema;
    }
}
exports.Its = Its;
