"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlMock = void 0;
const url_model_1 = require("./url.model");
class UrlMock extends url_model_1.Url {
    constructor(data) {
        const seedData = {
            id: 'asdasdsDFRFSSDAsda',
            enrollmentId: 'qweASwsDeAWdfsdfs',
            expirationDate: 1605245400000,
            link: 'https://proflyt-api-prod-pithp4flaq-uc.a.run.app/events/acknowledge/asdasdsDFRFSSDAsda',
        };
        super(seedData);
    }
}
exports.UrlMock = UrlMock;
