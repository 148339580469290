"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccomplishmentReportStatusType = void 0;
var AccomplishmentReportStatusType;
(function (AccomplishmentReportStatusType) {
    AccomplishmentReportStatusType["AwaitingReview"] = "Awaiting review";
    AccomplishmentReportStatusType["InReview"] = "In review";
    AccomplishmentReportStatusType["Logged"] = "Logged";
    AccomplishmentReportStatusType["Returned"] = "Returned";
})(AccomplishmentReportStatusType = exports.AccomplishmentReportStatusType || (exports.AccomplishmentReportStatusType = {}));
