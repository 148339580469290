"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangeChiefAdminRoleMock = void 0;
const index_1 = require("./index");
class ChangeChiefAdminRoleMock extends index_1.ChangeChiefAdminRole {
    constructor(data) {
        const seedData = {
            currentChiefAdminId: 'VKFfzK1tMwertoH854m1oWu8NEZ2',
            nextChiefAdminId: 'CJw7l5PyKlOyBqwnXlqTgOCbYRJ2',
            squadronId: 'N38raNf1DtZCIkpkaswedrftgyhgb',
        };
        super(seedData);
    }
}
exports.ChangeChiefAdminRoleMock = ChangeChiefAdminRoleMock;
