"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.accomplishmentReportSchema = void 0;
const yup = require("yup");
const enums_1 = require("../../enums");
const accomplishment_report_notes_1 = require("../accomplishment-report-notes");
const utilities_1 = require("../../utilities");
exports.accomplishmentReportSchema = yup.object().shape({
    table: yup.array().of(yup.object().shape({
        acc: yup.string(),
        accomp: yup.number(),
        task_accomp: yup.number(),
        data_in_phase: yup.number(),
        data_last_accomp: yup.number(),
        due_data: yup.number(),
        ovedue: yup.string(),
        pgm: yup.string(),
        qualification: yup.string(),
        rem: yup.number(),
        req: yup.number(),
        rest_code: yup.string(),
        task_id: yup.string().required(),
        task_name: yup.string().required(),
        wvd: yup.string(),
    })),
    id: yup.string().notRequired(),
    status: yup.string().oneOf((0, utilities_1.getEnumValues)(enums_1.AccomplishmentReportStatusType)).required(),
    userId: yup.string().required(),
    userName: yup.string().required(),
    userDutyPosition: yup.string().required(),
    eventId: yup.string().required(),
    eventName: yup.string().required(),
    createdAt: yup.number().required(),
    notes: yup.array().of(accomplishment_report_notes_1.accomplishmentReportNotesSchema).notRequired(),
    taskUpdatedBySarm: yup.array().of(yup.string()).notRequired(),
});
