"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Filter = void 0;
const filter_operator_1 = require("./filter-operator");
class Filter {
    constructor(field, operator, value) {
        this.field = field;
        this.operator = operator;
        this.value = value;
    }
    static fromValues(values) {
        const field = values.get('field');
        const operator = values.get('operator');
        const value = values.get('value');
        if (!field || !operator || !value) {
            throw new Error(`The filter is invalid`);
        }
        return new Filter(field, filter_operator_1.FilterOperator.fromValue(operator), value);
    }
}
exports.Filter = Filter;
