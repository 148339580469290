"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.failedPushNotificationsSchema = void 0;
const yup = require("yup");
const user_schema_1 = require("../user/user.schema");
exports.failedPushNotificationsSchema = yup.object().shape({
    id: yup.string().notRequired(),
    userId: yup.string().notRequired(),
    user: user_schema_1.userSchema.notRequired().nullable(),
    message: yup.string().required(),
    createdAt: yup.number().min(1577851200000).max(2524622400000).required(),
});
