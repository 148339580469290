"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorResponse = void 0;
const api_error_1 = require("../api-error");
const base_model_1 = require("../base-model");
const error_response_schema_1 = require("./error-response.schema");
class ErrorResponse extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
        for (let i = 0; i < this.errors.length; i++) {
            if (!(this.errors[i] instanceof api_error_1.ApiError)) {
                this.errors[i] = new api_error_1.ApiError(this.errors[i], skipValidation);
            }
        }
    }
    toString() {
        const header = `Error Response [${this.sourceTag}] - status ${this.statusCode}`;
        const errorLines = this.errors.map((e) => `${e}`);
        return `${header}\n\n${errorLines.join('\n')}`;
    }
    getSchema() {
        return error_response_schema_1.errorResponseSchema;
    }
    getDefaults() {
        return {
            timestamp: new Date(Date.now()),
        };
    }
}
exports.ErrorResponse = ErrorResponse;
