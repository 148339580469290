"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderType = exports.OrderTypes = void 0;
var OrderTypes;
(function (OrderTypes) {
    OrderTypes["ASC"] = "asc";
    OrderTypes["DESC"] = "desc";
    OrderTypes["NONE"] = "none";
})(OrderTypes = exports.OrderTypes || (exports.OrderTypes = {}));
class OrderType {
    constructor(value) {
        this.value = value;
    }
    static fromValue(value) {
        switch (value) {
            case OrderTypes.ASC:
                return new OrderType(OrderTypes.ASC);
            case OrderTypes.DESC:
                return new OrderType(OrderTypes.DESC);
            default:
                throw new Error(`The order type ${value} is invalid`);
        }
    }
    isNone() {
        return this.value === OrderTypes.NONE;
    }
    isAsc() {
        return this.value === OrderTypes.ASC;
    }
    throwErrorForInvalidValue(value) {
        throw new Error(`The order type ${value} is invalid`);
    }
}
exports.OrderType = OrderType;
