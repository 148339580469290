"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.eventHistorySchema = void 0;
const yup = require("yup");
const user_schema_1 = require("../user/user.schema");
const logging_action_types_enum_1 = require("../../enums/logging-action-types.enum");
const calendar_event_schema_1 = require("../calendar-event/calendar-event.schema");
const utilities_1 = require("../../utilities");
exports.eventHistorySchema = yup.object().shape({
    id: yup.string().notRequired(),
    eventId: yup.string().notRequired(),
    event: calendar_event_schema_1.calendarEventSchema.required(),
    createdAt: yup.number().min(1577851200000).max(2524622400000).required(),
    person: user_schema_1.userSchema.notRequired().nullable(),
    owner: user_schema_1.userSchema.required().nullable(),
    type: yup.string().required().oneOf((0, utilities_1.getEnumValues)(logging_action_types_enum_1.LoggingActionTypesType)),
});
