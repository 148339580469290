import { RoleType, User } from 'contracts';
import { IAuthStrategy } from './interfaces';
import { Observable } from 'rxjs';
// import { FirebaseStrategy } from './strategies/firebase.strategy';
import { Injectable } from '@angular/core';
import { KeycloakStrategy } from './strategies/keycloak.strategy';
import { RestStrategy } from './strategies/rest.strategy';
import { ITokenSetCredentials } from '../interfaces/token-set-credentials.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthProvider {
  public static getInstance(): AuthProvider {
    if (!AuthProvider.instance) {
      AuthProvider.instance = new AuthProvider();
    }

    return AuthProvider.instance;
  }

  private static instance: AuthProvider;
  private _strategy: IAuthStrategy;

  constructor() {}

  public useStrategy(strategy: IAuthStrategy) {
    this._strategy = strategy;
  }

  public get strategy(): IAuthStrategy {
    return this._strategy;
  }

  public login(email: string, password: string): Observable<User> {
    if (/* this._strategy instanceof FirebaseStrategy || */ this._strategy instanceof RestStrategy) {
      return this._strategy.login(email, password);
    }

    return null;
  }

  public logout(shouldNotRedirect?: boolean, areTokensAlreadyRevoked = false): void {
    this._strategy.logout(shouldNotRedirect, areTokensAlreadyRevoked);
  }

  public isAuth(): Observable<boolean> {
    return this._strategy.isAuth();
  }

  public getProflytUser(): Observable<User> {
    return this._strategy.getProflytUser();
  }

  public resetPassword(userEmail?: string): Observable<void> {
    if ((/* this._strategy instanceof FirebaseStrategy || */ this._strategy instanceof RestStrategy) && !userEmail) {
      throw new Error('User email required');
    }

    return this._strategy.resetPassword(userEmail);
  }

  public verifyUserPermissions(activeUserRoleType: RoleType, roleType: RoleType): boolean {
    return this._strategy.verifyUserPermissions(activeUserRoleType, roleType);
  }

  public changePassword(oldPassword: string, newPassword: string): Observable<void> {
    /* if (this._strategy instanceof FirebaseStrategy) {
      return this._strategy.changePassword(oldPassword, newPassword);
    } */

    return null;
  }

  public confirmPasswordReset(code: string, newPassword: string) {
    /* if (this._strategy instanceof FirebaseStrategy) {
      return this._strategy.confirmPasswordReset(code, newPassword);
    } */

    return null;
  }

  public init(): void {
    if (this._strategy instanceof KeycloakStrategy) {
      this._strategy.init();
    }
  }

  public authEvents(): void {
    this._strategy.authEvents();
  }
}
