"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTimezoneFromStandardTime = void 0;
function getTimezoneFromStandardTime(timezone) {
    /**
     * EST -> Eastern Standard Time
     * CST -> Central Standard Time
     * MST -> Mountain Standard Time
     * PST -> Pacific Standard Time
     * AKST -> Alaska Standard Time
     * HST -> Hawaii Standard Time
     * EAT -> Eastern Africa Time
     * CAT -> Central Africa Time
     * WAT -> West Africa Time
     * SAST -> South Africa Standard Time
     */
    const cityInTimezoneMap = {
        EST: 'America/Detroit',
        CST: 'America/Chicago',
        MST: 'America/Creston',
        PST: 'America/Los_Angeles',
        HST: 'Etc/GMT+10',
        DST: 'Etc/GMT+12',
        U: 'Etc/GMT+11',
        AKST: 'America/Anchorage',
        PDT: 'America/Santa_Isabel',
        PDT0: 'America/Los_Angeles',
        MDT: 'America/Chihuahua',
        MDT0: 'America/Boise',
        CAST: 'America/Belize',
        CDT0: 'America/Bahia_Banderas',
        CCST: 'America/Regina',
        SPST: 'America/Bogota',
        EDT: 'America/Detroit',
        UEDT: 'America/Indiana/Marengo',
        VST: 'America/Caracas',
        PYT: 'America/Asuncion',
        ADT: 'America/Glace_Bay',
        CBST: 'America/Campo_Grande',
        SWST: 'America/Anguilla',
        PSST: 'America/Santiago',
        NDT: 'America/St_Johns',
        ESAST: 'America/Sao_Paulo',
        AST: 'America/Argentina/La_Rioja',
        SEST: 'America/Araguaina',
        GDT: 'America/Godthab',
        MST0: 'America/Montevideo',
        BST: 'America/Bahia',
        U0: 'America/Noronha',
        MDT00: 'Atlantic/Cape_Verde',
        ADT0: 'America/Scoresbysund',
        CVST: 'Atlantic/Cape_Verde',
        MDT000: 'Africa/Casablanca',
        UTC: 'America/Danmarkshavn',
        GMT: 'Europe/Isle_of_Man',
        BST0: 'Europe/Isle_of_Man',
        GDT0: 'Atlantic/Canary',
        GST: 'Africa/Abidjan',
        WEDT: 'Arctic/Longyearbyen',
        CEDT: 'Europe/Belgrade',
        RDT: 'Africa/Ceuta',
        CEDT0: 'Europe/Sarajevo',
        WCAST: 'Africa/Algiers',
        NST: 'Africa/Windhoek',
        GDT00: 'Asia/Nicosia',
        MEDT: 'Asia/Beirut',
        EST0: 'Africa/Cairo',
        SDT: 'Asia/Damascus',
        EEDT: 'Asia/Nicosia',
        SAST: 'Africa/Blantyre',
        FDT: 'Europe/Helsinki',
        TDT: 'Europe/Istanbul',
        JDT: 'Asia/Jerusalem',
        LST: 'Africa/Tripoli',
        JST: 'Asia/Amman',
        AST0: 'Asia/Baghdad',
        KST: 'Europe/Kaliningrad',
        AST00: 'Asia/Aden',
        EAT: 'Africa/Addis_Ababa',
        MSK: 'Europe/Kirov',
        SAMT: 'Europe/Astrakhan',
        IDT: 'Asia/Tehran',
        AST000: 'Asia/Dubai',
        ADT00: 'Asia/Baku',
        MST00: 'Indian/Mahe',
        GET: 'Asia/Tbilisi',
        AST0000: 'Asia/Kabul',
        WAST: 'Antarctica/Mawson',
        YEKT: 'Asia/Yekaterinburg',
        PKT: 'Asia/Karachi',
        IST: 'Asia/Kolkata',
        SLST: 'Asia/Colombo',
        NST0: 'Asia/Kathmandu',
        CAST0: 'Antarctica/Vostok',
        BST00: 'Asia/Dhaka',
        MST000: 'Asia/Rangoon',
        SAST0: 'Antarctica/Davis',
        NCAST: 'Asia/Novokuznetsk',
        CST0: 'Asia/Hong_Kong',
        NAST: 'Asia/Krasnoyarsk',
        MPST: 'Asia/Brunei',
        WAST0: 'Antarctica/Casey',
        TST: 'Asia/Taipei',
        UST: 'Asia/Choibalsan',
        NAEST: 'Asia/Irkutsk',
        JST0: 'Asia/Dili',
        KST0: 'Asia/Pyongyang',
        CAST00: 'Australia/Adelaide',
        ACST: 'Australia/Darwin',
        EAST: 'Australia/Brisbane',
        AEST: 'Australia/Melbourne',
        WPST: 'Antarctica/DumontDUrville',
        TST0: 'Australia/Currie',
        YST: 'Asia/Chita',
        CPST: 'Antarctica/Macquarie',
        VST0: 'Asia/Sakhalin',
        NZST: 'Antarctica/McMurdo',
        U00: 'Etc/GMT-12',
        FST: 'Pacific/Fiji',
        MST0000: 'Asia/Anadyr',
        KDT: 'Asia/Kamchatka',
        TST00: 'Etc/GMT-13',
        SST: 'Pacific/Apia',
    };
    return cityInTimezoneMap[timezone];
}
exports.getTimezoneFromStandardTime = getTimezoneFromStandardTime;
