"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SocketChannels = void 0;
var SocketChannels;
(function (SocketChannels) {
    SocketChannels["EventUpdated"] = "event_updated_on_squadron_";
    SocketChannels["EventCreated"] = "event_created_on_squadron_";
    SocketChannels["EventDeleted"] = "event_deleted_on_squadron_";
    SocketChannels["AvailabilityUpdated"] = "availability_updated_on_squadron_";
    SocketChannels["AvailabilityCreated"] = "availability_created_on_squadron_";
    SocketChannels["AvailabilityDeleted"] = "availability_deleted_on_squadron_";
    SocketChannels["EventCommentCreated"] = "comment_created_on_event_";
    SocketChannels["NewCommentCreated"] = "comment_created_on_";
    SocketChannels["ResetUnreadNotificationCount"] = "reset_unread_notifications_count";
    SocketChannels["UnreadNotification"] = "unread_notification_";
})(SocketChannels = exports.SocketChannels || (exports.SocketChannels = {}));
