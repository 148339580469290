"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalendarEventTypes = void 0;
var CalendarEventTypes;
(function (CalendarEventTypes) {
    CalendarEventTypes["ALERTLINE"] = "ALERTLINE";
    CalendarEventTypes["SIM"] = "SIM";
    CalendarEventTypes["GROUND"] = "GROUND";
    CalendarEventTypes["FLIGHT"] = "FLIGHT";
    CalendarEventTypes["OST"] = "OST";
    CalendarEventTypes["TDY"] = "TDY";
})(CalendarEventTypes = exports.CalendarEventTypes || (exports.CalendarEventTypes = {}));
