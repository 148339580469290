import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LocalStorageKeys } from '../enums';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public getKey(key: LocalStorageKeys, isLocal = true): Observable<unknown> {
    if (isLocal) {
      const storageItem: string = localStorage.getItem(key as string);

      if (storageItem) {
        return of(this.convertToUsefulItem(storageItem));
      }

      return of(null);
    }

    return of(null);
  }

  public setKey(key: LocalStorageKeys, value: unknown, isLocal = true): Observable<void> {
    if (isLocal) {
      const valueToSave: string = this.convertToStorageItem(value);
      localStorage.setItem(key as string, valueToSave);

      return of(null);
    }

    return of(null);
  }

  public deleteKey(key: LocalStorageKeys) {
    localStorage.removeItem(key as string);

    return of(null);
  }

  public deleteOldKeys() {
    if (localStorage.getItem('acceptConditions') || localStorage.getItem('activeAddUserSquadron')) {
      localStorage.clear();
    }

    return of(null);
  }

  private convertToStorageItem(value: unknown): string {
    let valueToSave = null;
    try {
      valueToSave = JSON.stringify(value);
    } catch {
      valueToSave = value.toString();
    }

    return valueToSave;
  }

  private convertToUsefulItem(value: string): unknown {
    try {
      return JSON.parse(value);
    } catch {
      return value;
    }
  }
}
