"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateRandomId = void 0;
/**
 * Generates a random ID
 * Output example: _wdqbc34y6phk9sirqa30wpnev3q
 */
function generateRandomId() {
    return ('_' +
        Math.random().toString(36).substring(2, 11) +
        Math.random().toString(36).substring(2, 11) +
        Math.random().toString(36).substring(2, 11));
}
exports.generateRandomId = generateRandomId;
