"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoggingActionTypesType = void 0;
var LoggingActionTypesType;
(function (LoggingActionTypesType) {
    LoggingActionTypesType["CreateEvent"] = "CreateEvent";
    LoggingActionTypesType["UpdateEvent"] = "UpdateEvent";
    LoggingActionTypesType["ChangeEventFull"] = "ChangeEventFull";
    LoggingActionTypesType["ChangeEventDefault"] = "ChangeEventDefault";
    LoggingActionTypesType["NotifyAllUserEvent"] = "NotifyAllUserEvent";
    LoggingActionTypesType["AddUserEvent"] = "AddUserEvent";
    LoggingActionTypesType["DeleteUserEvent"] = "DeleteUserEvent";
    LoggingActionTypesType["UpdateUserEvent"] = "UpdateUserEvent";
    LoggingActionTypesType["RequestToParticipate"] = "RequestToParticipate";
    LoggingActionTypesType["ApprovedUserEvent"] = "ApprovedUserEvent";
    LoggingActionTypesType["DeclineUserEvent"] = "DeclineUserEvent";
    LoggingActionTypesType["RemaksUserEvent"] = "RemaksUserEvent";
    LoggingActionTypesType["PayStatusUserEvent"] = "PayStatusUserEvent";
    LoggingActionTypesType["DutyPositionUserEvent"] = "DutyPositionUserEvent";
    LoggingActionTypesType["AcknowledgeEventVerification"] = "AcknowledgeEventVerification";
    LoggingActionTypesType["RejectEventVerification"] = "RejectEventVerification";
})(LoggingActionTypesType = exports.LoggingActionTypesType || (exports.LoggingActionTypesType = {}));
