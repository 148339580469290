"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Rating = void 0;
const rating_schema_1 = require("./rating.schema");
const base_model_1 = require("../base-model");
class Rating extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {
            user: null,
            hours: 0,
            minutes: 0,
            rating: 0,
            message: '',
            createdAt: 0,
        };
    }
    getSchema() {
        return rating_schema_1.ratingSchema;
    }
}
exports.Rating = Rating;
