"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.eventCommentSchema = void 0;
const yup = require("yup");
exports.eventCommentSchema = yup.object().shape({
    id: yup.string().notRequired(),
    user: yup.object().required(),
    eventId: yup.string().required(),
    comment: yup.string().required(),
    createdAt: yup.number().required(),
    updatedAt: yup.number().notRequired(),
    deletedAt: yup.number().notRequired(),
});
