"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.aircrewTypeSchema = void 0;
const yup = require("yup");
exports.aircrewTypeSchema = yup.object().shape({
    id: yup.string().notRequired(),
    type: yup.string().required(),
    dutyPositions: yup.array().of(yup.string()).notRequired(),
    searchKeys: yup.array().of(yup.string()),
});
