"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserFreeToParticipateTypeMock = void 0;
const user_event_relation_times_to_check_model_1 = require("./user-event-relation-times-to-check.model");
class UserFreeToParticipateTypeMock extends user_event_relation_times_to_check_model_1.UserEventRelationTimesToCheck {
    constructor(data) {
        const seedData = {
            userId: 'WEFrwANrJLcSs4uNkKfoc0JGkd23',
            eventId: '7wFWahpyOOfK09jbQ03wVOtVwlz1',
            eventStartDate: 1608796223085,
            eventEndDate: 160879622345621,
            amountOfHoursBackToCheck: 12,
        };
        super(seedData);
    }
}
exports.UserFreeToParticipateTypeMock = UserFreeToParticipateTypeMock;
