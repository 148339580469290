import { APP_INITIALIZER, NgModule, Provider } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from './store-app/reducers';
import { environment } from '../../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { CustomSerializer } from './store-app/custom-route-serializer';
import { NetworkErrorInterceptor, AuthInterceptor } from './interceptors';
import { EffectsModule } from '@ngrx/effects';
import { authStateFeatureKey, authReducer } from '../auth/store-auth/reducers/auth-state.reducer';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { initializeKeycloak } from '../auth/providers/core/keycloak-factory';

const providers: Provider[] = [
  { provide: RouterStateSerializer, useClass: CustomSerializer },
  { provide: HTTP_INTERCEPTORS, useClass: NetworkErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];

if (environment.authProvider === 'keycloak') {
  providers.push({
    provide: APP_INITIALIZER,
    useFactory: initializeKeycloak,
    multi: true,
    deps: [KeycloakService],
  });
}


@NgModule({
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true },
    }),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          maxAge: 25, // Returns last 25 states
          logOnly: environment.production, // Boolean. Restricts to log only depending on env
        })
      : [],
    StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
    StoreModule.forFeature(authStateFeatureKey, authReducer),
    EffectsModule.forRoot([]),
    KeycloakAngularModule,
  ],
  // Interceptors and global services here
  providers,
})
export class CoreModule {}
