"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.squadronSchema = void 0;
const yup = require("yup");
const time_zone_enum_1 = require("../../enums/time-zone.enum");
const user_1 = require("../user");
const aircrew_composition_1 = require("./aircrew-composition");
const point_of_contact_1 = require("./point-of-contact");
const information_1 = require("./information");
exports.squadronSchema = yup.object().shape({
    id: yup.string().notRequired(),
    name: yup.string().notRequired(),
    mds: yup.string().notRequired(),
    createdAt: yup.number().required(),
    sortByPocName: yup.string(),
    searchKeys: yup.array().of(yup.string()),
    aircrewComposition: aircrew_composition_1.aircrewCompositionSchema,
    pointOfContact: point_of_contact_1.pointOfContactSchema,
    isActive: yup.boolean().required(),
    users: yup
        .array()
        .of(yup.object().shape({ user: user_1.userSchema }))
        .notRequired(),
    harmLocation: yup.string().max(20).notRequired(),
    timeZone: yup.string().oneOf(Object.keys(time_zone_enum_1.TimeZoneType)).notRequired(),
    enabledRating: yup.boolean().notRequired(),
    pocName: yup.string().notRequired(),
    information: information_1.squadronInformationSchema.notRequired(),
    wing: yup.string().notRequired(),
    group: yup.string().notRequired(),
});
