"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.itsModificationsSchema = void 0;
const yup = require("yup");
const its_modification_action_enum_1 = require("../../enums/its-modification-action.enum");
const utilities_1 = require("../../utilities");
exports.itsModificationsSchema = yup.object().shape({
    table: yup.array().of(yup.object().shape({
        acc: yup.string(),
        accomp: yup.number(),
        task_accomp: yup.number(),
        data_in_phase: yup.number(),
        data_last_accomp: yup.number(),
        due_data: yup.number(),
        ovedue: yup.string(),
        pgm: yup.string(),
        qualification: yup.string(),
        rem: yup.number(),
        req: yup.number(),
        rest_code: yup.string(),
        task_id: yup.string().required(),
        task_name: yup.string().required(),
        wvd: yup.string(),
    })),
    modifications: yup.array().of(yup.object().shape({
        eventId: yup.string().required(),
        eventName: yup.string().required(),
        taskId: yup.string().required(),
        taskName: yup.string().required(),
        action: yup.string().oneOf((0, utilities_1.getEnumValues)(its_modification_action_enum_1.ItsModificationActionType)).required(),
        amount: yup.number().required(),
        date: yup.number().required()
    })),
});
