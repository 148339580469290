"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.APISuccessMessage = void 0;
const base_model_1 = require("../base-model");
const api_success_message_schema_1 = require("./api-success-message.schema");
class APISuccessMessage extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
        this.error = false;
    }
    getDefaults() {
        return {
            message: 'Processed',
            error: false,
        };
    }
    getSchema() {
        return api_success_message_schema_1.apiSuccessMessageSchema;
    }
}
exports.APISuccessMessage = APISuccessMessage;
