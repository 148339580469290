"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PusherEventsType = void 0;
var PusherEventsType;
(function (PusherEventsType) {
    PusherEventsType["Create"] = "Create";
    PusherEventsType["Update"] = "Update";
    PusherEventsType["Delete"] = "Delete";
    PusherEventsType["Acknowledge"] = "Acknowledge";
})(PusherEventsType = exports.PusherEventsType || (exports.PusherEventsType = {}));
