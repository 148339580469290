"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Url = void 0;
const url_schema_1 = require("./url.schema");
const base_model_1 = require("../base-model");
class Url extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
        const actualDate = new Date();
        // add 3 days
        actualDate.setDate(actualDate.getDate() + 3);
        this.expirationDate = actualDate.getTime();
    }
    getDefaults() {
        return {};
    }
    getSchema() {
        return url_schema_1.urlSchema;
    }
}
exports.Url = Url;
