"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserNotificationConfiguration = void 0;
const notification_configuration_schema_1 = require("./notification-configuration.schema");
const base_model_1 = require("../../../base-model");
class UserNotificationConfiguration extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {
            email: true,
            push: true,
        };
    }
    getSchema() {
        return notification_configuration_schema_1.userNotificationConfigurationSchema;
    }
}
exports.UserNotificationConfiguration = UserNotificationConfiguration;
