"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.availabilityDateRangeSchema = void 0;
const yup = require("yup");
const enums_1 = require("../../enums");
const utilities_1 = require("../../utilities");
exports.availabilityDateRangeSchema = yup.object().shape({
    id: yup.string().notRequired().nullable(),
    relatedUserId: yup.string().required(),
    startDate: yup.number().min(1577851200000).max(2524622400000).required(),
    endDate: yup.number().min(1577851200000).max(2524622400000).required(),
    note: yup.string().notRequired().nullable(),
    type: yup.string().required().oneOf((0, utilities_1.getEnumValues)(enums_1.AvailabilityTypes)),
    relatedSquadronId: yup.string().required(),
    firstHalfDayPayStatus: yup.number().notRequired().nullable(),
    secondHalfDayPayStatus: yup.number().notRequired().nullable(),
    firstHalfDayPayStatusType: yup.string().notRequired().nullable(),
    secondHalfDayPayStatusType: yup.string().notRequired().nullable(),
    isUta: yup.bool().notRequired(),
});
