"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateOtherTimes = exports.validateDuration = void 0;
function validateDuration(duration) {
    // Throw error (duration not in valid range) in case of undefined
    const formatDuration = (duration === null || duration === void 0 ? void 0 : duration.replace(':', '')) || '-1';
    const durationNumber = parseInt(formatDuration, 10);
    return durationNumber >= 0 && durationNumber <= 2359;
}
exports.validateDuration = validateDuration;
function validateOtherTimes(duration) {
    if (!duration) {
        return true;
    }
    // Throw error (duration not in valid range) in case of undefined
    const formatDuration = (duration === null || duration === void 0 ? void 0 : duration.replace(':', '')) || '-1';
    const durationNumber = parseInt(formatDuration, 10);
    return durationNumber >= 0 && durationNumber <= 2359;
}
exports.validateOtherTimes = validateOtherTimes;
