"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpecificationRoleType = void 0;
var SpecificationRoleType;
(function (SpecificationRoleType) {
    SpecificationRoleType["AircrewScheduling"] = "AircrewScheduling";
    SpecificationRoleType["Training"] = "Training";
    SpecificationRoleType["CurrentOps"] = "CurrentOps";
    SpecificationRoleType["StanEval"] = "StanEval";
    SpecificationRoleType["CSS"] = "CSS";
    SpecificationRoleType["Maintenance"] = "Maintenance";
    SpecificationRoleType["SQ_CC"] = "SQ_CC";
    SpecificationRoleType["OSS_CC"] = "OSS_CC";
    SpecificationRoleType["OG_CC"] = "OG_CC";
    SpecificationRoleType["WG_CC"] = "WG_CC";
    SpecificationRoleType["MX_CC"] = "MX_CC";
    /* SARM = 'SARM',
    COMMAND_POST = 'COMMAND_POST', */
})(SpecificationRoleType = exports.SpecificationRoleType || (exports.SpecificationRoleType = {}));
