"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserEventRelationMock = void 0;
const _1 = require(".");
class UserEventRelationMock extends _1.UserEventRelation {
    constructor(data) {
        const seedData = {
            id: 'kb64j2OmFWULPCHi1NNjAGrtX953',
            userId: '23aqWSj2OmFWULPCHi1NNjAGrtwsQA',
            eventId: '46rftgOmFWULPCHi1NN',
            firstHalfDay: 2,
            secondHalfDay: 1,
            isApprovedToParticipateInEvent: true,
            isAwaitingVerification: false,
            note: '',
            userDutyPositionForEvent: '',
            remarks: ['A', 'V'],
            availabilityId: '23aeRtyuHWULPCHi1NNjAGrt',
            rank: 1,
        };
        super(seedData);
    }
}
exports.UserEventRelationMock = UserEventRelationMock;
