"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserMetadata = void 0;
const user_metadata_schema_1 = require("./user-metadata.schema");
const base_model_1 = require("../../base-model");
class UserMetadata extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
        this.note = data === null || data === void 0 ? void 0 : data.note;
        this.unreadNotificationsCount = data === null || data === void 0 ? void 0 : data.unreadNotificationsCount;
        if (data === null || data === void 0 ? void 0 : data.firstLoginTime) {
            this.firstLoginTime = data === null || data === void 0 ? void 0 : data.firstLoginTime;
        }
    }
    getDefaults() {
        return {
            note: '',
            firstLoginTime: 0,
            unreadNotificationsCount: 0,
        };
    }
    getSchema() {
        return user_metadata_schema_1.userMetadataSchema;
    }
}
exports.UserMetadata = UserMetadata;
