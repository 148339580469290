"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseModel = void 0;
const yup_1 = require("yup");
/** Base model */
class BaseModel {
    constructor(data, skipValidation) {
        // Select if we get the default data or the incoming data for the object
        const seedData = Object.assign({}, this.getDefaults(), data);
        if (skipValidation) {
            Object.assign(this, seedData);
        }
        else {
            const validData = this.validate({ data: seedData });
            Object.assign(this, validData);
        }
    }
    validate(opts) {
        const options = Object.assign({ strict: false }, opts ? opts.options : {});
        const data = opts && opts.data ? opts.data : this;
        try {
            return this.getSchema().noUnknown().validateSync(data, options);
        }
        catch (e) {
            const originalError = e;
            // Copy, rename and re-throw the error in a more user-friendly way
            const error = new yup_1.ValidationError(originalError.errors[0], originalError.value, originalError.path, originalError.type);
            error.name = `${this.constructor.name} ${originalError.name}`;
            error.inner = originalError.inner;
            error.message = originalError.message;
            error.params = originalError.params;
            error.errors = originalError.errors;
            throw error;
        }
    }
}
exports.BaseModel = BaseModel;
