"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArctEntity = void 0;
const base_model_1 = require("../../../../base-model");
const arct_entity_schedule_1 = require("./arct-entity.schedule");
class ArctEntity extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {};
    }
    getSchema() {
        return arct_entity_schedule_1.arctEntitySchema;
    }
}
exports.ArctEntity = ArctEntity;
