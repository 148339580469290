"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Afto781 = void 0;
const afto781_schema_1 = require("./afto781.schema");
const official_form_base_model_1 = require("../official-form-base-model");
class Afto781 extends official_form_base_model_1.OfficialFormBaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {
            missionDataRows: [],
            aircrewDataRows: [],
            aircrewDataRowsReverse: [],
        };
    }
    getSchema() {
        return afto781_schema_1.afto781Schema;
    }
}
exports.Afto781 = Afto781;
