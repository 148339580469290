export enum LocalStorageKeys {
  AreConditionsAccepted = 'AreConditionsAccepted',
  SelectedSquadronInDropdown = 'SelectedSquadronInDropdown',
  EventsOnEditMode = 'EventsOnEditMode',
  CalendarViewDate = 'CalendarViewDate',
  CalendarSelectedSquadron = 'CalendarSelectedSquadron',
  ReceivedNotificationsCounter = 'ReceivedNotificationsCounter',
  EventComments = 'EventComments',
  TokenSet = 'TokenSet',
}
