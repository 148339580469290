import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

/**
 * This service was created because Angular's Location service is problematic within android builds
 * It registers max of 2 routes and allows to navigate with Angular Router to last visited route
 */
@Injectable({ providedIn: 'root' })
export class NavigationHistoryService {
  private navigationHistory: string[] = [];
  private minimumHistoryLength = 2;

  constructor(private router: Router) {}

  public addLastRouteVisited(route: string): void {
    this.navigationHistory.push(route);

    // this part is to avoid having too much history
    // and for this app, 10 items is enough
    if (this.navigationHistory.length >= 10) {
      this.navigationHistory.shift();
    }
  }

  public goBack(): void {
    if (this.navigationHistory.length < this.minimumHistoryLength) {
      this.router.navigate(['/']);

      return;
    }

    const lastRouteVisitedIndex = this.navigationHistory.length - this.minimumHistoryLength;
    const routeToNavigate: string[] = this.navigationHistory[lastRouteVisitedIndex]?.split('/') || [];

    this.navigationHistory.splice(lastRouteVisitedIndex, this.minimumHistoryLength);

    if (routeToNavigate.length) {
      routeToNavigate[0] = '/';
      this.router.navigate(routeToNavigate);

      return;
    }

    this.router.navigate(['/']);
  }
}
