"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorTag = void 0;
/**
 * Every error must be assigned to a unique error tag to help debugging.
 * Please add a new tag here when creating a new error case.
 * Every microservice we create must have a different ErrorTag Enum.
 * All Error Throws must be of type AppException:

 * Method 1:
 * if (somethingBad) this.thrower.throwAppException(...)

 */
var ErrorTag;
(function (ErrorTag) {
    ErrorTag["ERR_001"] = "ERR_001";
    ErrorTag["ERR_002"] = "ERR_002";
    ErrorTag["ERR_003"] = "ERR_003";
    ErrorTag["ERR_004"] = "ERR_004";
    ErrorTag["ERR_005"] = "ERR_005";
    ErrorTag["ERR_006"] = "ERR_006";
    ErrorTag["ERR_007"] = "ERR_007";
    ErrorTag["ERR_008"] = "ERR_008";
    ErrorTag["ERR_009"] = "ERR_009";
    ErrorTag["ERR_010"] = "ERR_010";
    ErrorTag["ERR_011"] = "ERR_011";
    ErrorTag["ERR_012"] = "ERR_012";
    ErrorTag["ERR_013"] = "ERR_013";
    ErrorTag["ERR_014"] = "ERR_014";
    ErrorTag["ERR_015"] = "ERR_015";
    ErrorTag["ERR_016"] = "ERR_016";
    ErrorTag["ERR_017"] = "ERR_017";
    ErrorTag["ERR_018"] = "ERR_018";
    ErrorTag["ERR_019"] = "ERR_019";
    ErrorTag["ERR_020"] = "ERR_020";
    ErrorTag["ERR_021"] = "ERR_021";
    ErrorTag["ERR_022"] = "ERR_022";
    ErrorTag["ERR_023"] = "ERR_023";
    ErrorTag["ERR_024"] = "ERR_024";
    ErrorTag["ERR_025"] = "ERR_025";
    ErrorTag["ERR_026"] = "ERR_026";
    ErrorTag["ERR_027"] = "ERR_027";
    ErrorTag["ERR_028"] = "ERR_028";
    ErrorTag["ERR_029"] = "ERR_029";
    ErrorTag["ERR_030"] = "ERR_030";
    ErrorTag["ERR_031"] = "ERR_031";
    ErrorTag["ERR_032"] = "ERR_032";
    ErrorTag["ERR_033"] = "ERR_033";
    ErrorTag["ERR_034"] = "ERR_034";
    ErrorTag["ERR_035"] = "ERR_035";
    ErrorTag["ERR_036"] = "ERR_036";
    ErrorTag["ERR_037"] = "ERR_037";
    ErrorTag["ERR_038"] = "ERR_038";
    ErrorTag["ERR_039"] = "ERR_039";
    ErrorTag["ERR_040"] = "ERR_040";
    ErrorTag["ERR_041"] = "ERR_041";
    ErrorTag["ERR_042"] = "ERR_042";
    ErrorTag["ERR_043"] = "ERR_043";
    ErrorTag["ERR_044"] = "ERR_044";
    ErrorTag["ERR_045"] = "ERR_045";
    ErrorTag["ERR_046"] = "ERR_046";
    ErrorTag["ERR_047"] = "ERR_047";
    ErrorTag["ERR_048"] = "ERR_048";
    ErrorTag["ERR_049"] = "ERR_049";
    ErrorTag["ERR_050"] = "ERR_050";
    ErrorTag["ERR_051"] = "ERR_051";
    ErrorTag["ERR_052"] = "ERR_052";
    ErrorTag["ERR_053"] = "ERR_053";
    ErrorTag["ERR_054"] = "ERR_054";
    ErrorTag["ERR_055"] = "ERR_055";
    ErrorTag["ERR_056"] = "ERR_056";
    ErrorTag["ERR_057"] = "ERR_057";
    ErrorTag["ERR_058"] = "ERR_058";
    ErrorTag["ERR_059"] = "ERR_059";
    ErrorTag["ERR_060"] = "ERR_060";
    ErrorTag["ERR_061"] = "ERR_061";
    ErrorTag["ERR_062"] = "ERR_062";
    ErrorTag["ERR_063"] = "ERR_063";
    ErrorTag["ERR_064"] = "ERR_064";
    ErrorTag["ERR_065"] = "ERR_065";
    ErrorTag["ERR_066"] = "ERR_066";
    ErrorTag["ERR_067"] = "ERR_067";
    ErrorTag["ERR_068"] = "ERR_068";
    ErrorTag["ERR_069"] = "ERR_069";
    ErrorTag["ERR_070"] = "ERR_070";
    ErrorTag["ERR_071"] = "ERR_071";
    ErrorTag["ERR_072"] = "ERR_072";
    ErrorTag["ERR_073"] = "ERR_073";
    ErrorTag["ERR_074"] = "ERR_074";
    ErrorTag["ERR_075"] = "ERR_075";
    ErrorTag["ERR_076"] = "ERR_076";
    ErrorTag["ERR_077"] = "ERR_077";
    ErrorTag["ERR_078"] = "ERR_078";
    ErrorTag["ERR_079"] = "ERR_079";
    ErrorTag["ERR_080"] = "ERR_080";
    ErrorTag["ERR_081"] = "ERR_081";
    ErrorTag["ERR_082"] = "ERR_082";
    ErrorTag["ERR_083"] = "ERR_083";
    ErrorTag["ERR_084"] = "ERR_084";
    ErrorTag["ERR_085"] = "ERR_085";
    ErrorTag["ERR_086"] = "ERR_086";
    ErrorTag["ERR_087"] = "ERR_087";
    ErrorTag["ERR_088"] = "ERR_088";
    ErrorTag["ERR_089"] = "ERR_089";
    ErrorTag["ERR_090"] = "ERR_090";
    ErrorTag["ERR_091"] = "ERR_091";
    ErrorTag["ERR_092"] = "ERR_092";
    ErrorTag["ERR_093"] = "ERR_093";
    ErrorTag["ERR_094"] = "ERR_094";
    ErrorTag["ERR_095"] = "ERR_095";
    ErrorTag["ERR_096"] = "ERR_096";
    ErrorTag["ERR_097"] = "ERR_097";
    ErrorTag["ERR_404"] = "ERR_404";
})(ErrorTag = exports.ErrorTag || (exports.ErrorTag = {}));
