import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AlertSystemService } from '../../alert-system/alert-system.service';
import { ToasterType } from '../../alert-system/core/enums';
import { Router } from '@angular/router';
import { IConnectionStatus } from '../../angular-calendar/core/interfaces/connection-status.interface';
// Capacitor
import { Network } from '@capacitor/network';

@Injectable()
export class NetworkErrorInterceptor implements HttpInterceptor {
  constructor(private alertSystemService: AlertSystemService, private router: Router) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        let errorMessage = '';
        if (error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Client-side error: ${error.error.message}`;
        } else {
          // backend error
          errorMessage = `${error.message}`;
        }

        const unkownErrorText = 'Unknown Error';
        if (error.statusText && error.statusText === unkownErrorText) {
          this.checkNetWorkStatus(errorMessage);
        }

        if (error.status && error.status === 429) {
          this.alertSystemService.showToaster({
            title: 'The server is too busy handling simultaneous requests',
            content: 'Please contact support at support@proflytblue.com',
            type: ToasterType.Error,
          });
        }

        // TODO: Send log to DB

        return throwError(error);
      })
    );
  }

  private async checkNetWorkStatus(errorMessage) {
    const networkStatus: IConnectionStatus = await Network.getStatus();
    if (networkStatus.connected) {
      this.alertSystemService.showToaster({
        title: 'No response from the server',
        content: errorMessage,
        type: ToasterType.Error,
      });
    }
  }
}
