"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AircrewType = void 0;
const enums_1 = require("../../enums");
const aircrew_type_schema_1 = require("./aircrew-type.schema");
const base_model_1 = require("../base-model");
class AircrewType extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
        if ((data === null || data === void 0 ? void 0 : data.dutyPositions) && Array.isArray(data.dutyPositions)) {
            this.dutyPositions = data.dutyPositions;
        }
    }
    getDefaults() {
        return {
            type: enums_1.AircrewPositionType.External,
            dutyPositions: [],
        };
    }
    getSchema() {
        return aircrew_type_schema_1.aircrewTypeSchema;
    }
}
exports.AircrewType = AircrewType;
