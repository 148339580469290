"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasicUser = void 0;
const base_model_1 = require("../base-model");
const basic_user_schema_1 = require("./basic-user.schema");
class BasicUser extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {};
    }
    getSchema() {
        return basic_user_schema_1.basicUserSchema;
    }
}
exports.BasicUser = BasicUser;
