"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unmaskPhoneNumber = exports.maskPhoneNumber = void 0;
const enums_1 = require("../enums");
const maskPhoneNumber = (inputValue, outputFormat = enums_1.PhoneMaskFormat.USA) => {
    if (outputFormat !== enums_1.PhoneMaskFormat.USA) {
        throw Error('Phone mask format not implemented');
    }
    const noDigitsRegex = /\D/g;
    const countryCodeSection = 3;
    const secondNumberSection = 6;
    const thirdNumberSection = 10;
    if (!inputValue) {
        return '';
    }
    let finalStringValue = inputValue.replace(noDigitsRegex, '');
    if (finalStringValue.length === 0) {
        finalStringValue = '';
    }
    else if (finalStringValue.length <= countryCodeSection) {
        finalStringValue = finalStringValue.replace(/^(\d{0,3})/, '($1)');
    }
    else if (finalStringValue.length <= secondNumberSection) {
        finalStringValue = finalStringValue.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
    }
    else if (finalStringValue.length <= thirdNumberSection) {
        finalStringValue = finalStringValue.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    }
    else {
        finalStringValue = finalStringValue.substring(0, thirdNumberSection);
        finalStringValue = finalStringValue.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    }
    return finalStringValue;
};
exports.maskPhoneNumber = maskPhoneNumber;
const unmaskPhoneNumber = (inputValue, inputFormat = enums_1.PhoneMaskFormat.USA) => {
    if (inputFormat !== enums_1.PhoneMaskFormat.USA) {
        throw Error('Phone mask format not implemented');
    }
    const nonDigitRegex = /\D+/g;
    return Number(inputValue.replace(nonDigitRegex, ''));
};
exports.unmaskPhoneNumber = unmaskPhoneNumber;
