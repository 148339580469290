"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Order = void 0;
const order_type_1 = require("./order-type");
class Order {
    constructor(orderBy, orderType) {
        this.orderBy = orderBy;
        this.orderType = orderType;
    }
    static fromValues(orderBy, orderType) {
        if (!orderBy) {
            return Order.none();
        }
        return new Order(orderBy, order_type_1.OrderType.fromValue(orderType || order_type_1.OrderTypes.ASC));
    }
    static none() {
        return new Order('', new order_type_1.OrderType(order_type_1.OrderTypes.NONE));
    }
    static desc(orderBy) {
        return new Order(orderBy, new order_type_1.OrderType(order_type_1.OrderTypes.DESC));
    }
    static asc(orderBy) {
        return new Order(orderBy, new order_type_1.OrderType(order_type_1.OrderTypes.ASC));
    }
    hasOrder() {
        return !this.orderType.isNone();
    }
}
exports.Order = Order;
