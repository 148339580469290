"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateNumericPassword = exports.generateNewPassword = void 0;
// String.fromCharCode() function is used to create a string from the given sequence of UTF-16 code units.
const getRandomUpperCase = () => {
    return String.fromCharCode(Math.floor(Math.random() * 26) + 65);
};
const getRandomLowerCase = () => {
    return String.fromCharCode(Math.floor(Math.random() * 26) + 97);
};
const getRandomNumber = () => {
    return String.fromCharCode(Math.floor(Math.random() * 10) + 48);
};
const getRandomSymbol = () => {
    const symbols = '!@#$%^&*(){}[]=<>/,.|~?';
    return symbols[Math.floor(Math.random() * symbols.length)];
};
const generateNewPassword = () => {
    const passwordLength = 10;
    let finalPassword = getRandomUpperCase();
    finalPassword += getRandomNumber();
    for (let charIndex = 0; charIndex < passwordLength - 2; charIndex++) {
        finalPassword += getRandomLowerCase();
    }
    finalPassword += getRandomSymbol();
    return finalPassword;
};
exports.generateNewPassword = generateNewPassword;
const generateNumericPassword = () => {
    return Math.random().toString().slice(2, 12);
};
exports.generateNumericPassword = generateNumericPassword;
