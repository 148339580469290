"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthProviderType = void 0;
var AuthProviderType;
(function (AuthProviderType) {
    AuthProviderType["keycloak"] = "keycloak";
    AuthProviderType["firebase"] = "firebase";
    AuthProviderType["rest"] = "rest";
    AuthProviderType["gamewarden"] = "gamewarden";
})(AuthProviderType = exports.AuthProviderType || (exports.AuthProviderType = {}));
