"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FtlType = void 0;
var FtlType;
(function (FtlType) {
    FtlType["A"] = "A";
    FtlType["B"] = "B";
    FtlType["C"] = "C";
    FtlType["Q"] = "Q";
    FtlType["N"] = "N";
})(FtlType = exports.FtlType || (exports.FtlType = {}));
