"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.eventAircraftDetailSchema = void 0;
const yup = require("yup");
const utilities_1 = require("../../../utilities");
const enums_1 = require("../../../enums");
exports.eventAircraftDetailSchema = yup.object().shape({
    id: yup.string().notRequired(),
    tailNumber: yup.string().notRequired(),
    parkingSpot: yup.string().notRequired(),
    fulRequired: yup.string().matches(/^\d{1,9}$/, "Ful Required Must be only digits").notRequired(),
    aircraftStatus: yup.string().oneOf((0, utilities_1.getEnumValues)(enums_1.AircraftStatusType)).nullable().default(enums_1.AircraftStatusType.EMPTY).notRequired(),
    reverseTail: yup.string().notRequired(),
});
