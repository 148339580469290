"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserEventRelationTimesToCheck = void 0;
const user_event_relation_times_to_check_schema_1 = require("./user-event-relation-times-to-check.schema");
const base_model_1 = require("../base-model");
class UserEventRelationTimesToCheck extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {
            eventId: '',
            eventStartDate: 0,
            eventEndDate: 0,
            amountOfHoursBackToCheck: 12,
        };
    }
    getSchema() {
        return user_event_relation_times_to_check_schema_1.userEventRelationTimesToCheckSchema;
    }
}
exports.UserEventRelationTimesToCheck = UserEventRelationTimesToCheck;
