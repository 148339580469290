"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccomplishmentReport = void 0;
const accomplishment_report_schema_1 = require("./accomplishment-report.schema");
const base_model_1 = require("../base-model");
class AccomplishmentReport extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {};
    }
    getSchema() {
        return accomplishment_report_schema_1.accomplishmentReportSchema;
    }
}
exports.AccomplishmentReport = AccomplishmentReport;
