"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AircrewComposition = void 0;
const aircrew_composition_schema_1 = require("./aircrew-composition.schema");
const base_model_1 = require("../../base-model");
class AircrewComposition extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {
            primaryDPs: [],
            dutyPositions: [],
        };
    }
    getSchema() {
        return aircrew_composition_schema_1.aircrewCompositionSchema;
    }
}
exports.AircrewComposition = AircrewComposition;
