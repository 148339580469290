import { createAction, props } from '@ngrx/store';
import { User } from 'contracts';

export const SetAuthStatus = createAction(
  '[Auth] Set current auth stats',
  props<{ isLoggedIn: boolean; user: User }>()
);

export const SetBiometricSecurityStatus = createAction(
  '[Auth] Set current biometric security status',
  props<{ canLoginWithBiometric: boolean }>()
);

export const ResetAuthState = createAction('[Auth] Reset auth state');

export const UpdateUnreadNotificationsCount = createAction(
  '[Auth] Update unread user notifications count',
  props<{ unreadNotificationsCount: number }>()
);
