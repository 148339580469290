"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AircrewTypeMock = void 0;
const enums_1 = require("../../enums");
const aircrew_type_model_1 = require("./aircrew-type.model");
class AircrewTypeMock extends aircrew_type_model_1.AircrewType {
    constructor(data) {
        const seedData = Object.assign({ type: enums_1.AircrewPositionType.Pilot, dutyPositions: ['MP'], searchKeys: ['a', 'ab', 'abc', 'abc1'] }, data);
        super(seedData);
    }
}
exports.AircrewTypeMock = AircrewTypeMock;
