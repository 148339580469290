"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Squadron = void 0;
const aircrew_composition_1 = require("./aircrew-composition");
const point_of_contact_1 = require("./point-of-contact");
const squadron_schema_1 = require("./squadron.schema");
const base_model_1 = require("../base-model");
class Squadron extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
        if (data === null || data === void 0 ? void 0 : data.pointOfContact) {
            this.pointOfContact = new point_of_contact_1.PointOfContact(data === null || data === void 0 ? void 0 : data.pointOfContact);
            this.sortByPocName = `${this.pointOfContact.firstName} ${this.pointOfContact.lastName}`;
        }
    }
    getDefaults() {
        return {
            searchKeys: [],
            aircrewComposition: new aircrew_composition_1.AircrewComposition(),
            isActive: true,
            harmLocation: undefined,
        };
    }
    getSchema() {
        return squadron_schema_1.squadronSchema;
    }
}
exports.Squadron = Squadron;
