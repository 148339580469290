"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorMessage = void 0;
var ErrorMessage;
(function (ErrorMessage) {
    ErrorMessage["TokenExpired"] = "Token expired";
    ErrorMessage["AuthRequired"] = "Authentication required";
    ErrorMessage["ActionNotPermitted"] = "User can't perform this operation";
    ErrorMessage["MissingBodyPayload"] = "More parameters are needed";
    ErrorMessage["InternalError"] = "Internal server error";
    ErrorMessage["NewPasswordTooShort"] = "Password is not long enough";
})(ErrorMessage = exports.ErrorMessage || (exports.ErrorMessage = {}));
