"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.squadronInformationSchema = void 0;
const yup = require("yup");
const utilities_1 = require("../../../utilities");
const enums_1 = require("../../../enums");
exports.squadronInformationSchema = yup.object().shape({
    homeAirfield: yup.string().notRequired(),
    country: yup.string().notRequired(),
    serviceBranch: yup.string().oneOf((0, utilities_1.getEnumValues)(enums_1.ServiceBranchType)).notRequired(),
    component: yup.string().oneOf((0, utilities_1.getEnumValues)(enums_1.ComponentType)).notRequired(),
    refuelingCapabilities: yup.string().oneOf((0, utilities_1.getEnumValues)(enums_1.RefuelingCapabilitiesType)).notRequired(),
    lowLevels: yup.boolean().notRequired(),
    airToAirOrAerobics: yup.boolean().notRequired(),
    fuelTracking: yup.string().oneOf((0, utilities_1.getEnumValues)(enums_1.FuelTrackingType)).notRequired(),
});
