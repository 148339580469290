"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userEventRelationTimesToCheckSchema = void 0;
const yup = require("yup");
exports.userEventRelationTimesToCheckSchema = yup.object().shape({
    userId: yup.string().required(),
    eventId: yup.string().notRequired(),
    eventStartDate: yup.number().notRequired(),
    eventEndDate: yup.number().notRequired(),
    amountOfHoursBackToCheck: yup.number().notRequired(),
});
