import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAuthState, authStateFeatureKey } from '../reducers/auth-state.reducer';

export const selectAuthState = createFeatureSelector<IAuthState>(authStateFeatureKey);

export const selectLoginStatus = createSelector(selectAuthState, (authState) => authState.isLoggedIn);

export const selectAuthUser = createSelector(selectAuthState, (authState) => authState.user);
export const selectBiometricSecurityStatus = createSelector(
  selectAuthState,
  (authState) => authState.canLoginWithBiometric
);
