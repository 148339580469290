"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItsMock = void 0;
const its_model_1 = require("./its.model");
class ItsMock extends its_model_1.Its {
    constructor(data) {
        const seedData = {
            id: 'asdasdsDFRFSSDAsda',
            userId: 'asdasdsDFRFSSDAsda',
            squadronId: 'asdasdsDFRFSSDAsda',
            squadronName: 'Delta',
            details: {
                as_of: 1634101200000,
                crew_position: 'MBC',
                grade: 'SSGT',
                name: 'BARLOW, BENJAMIN J',
                pcn: 'SA036-T10',
                physical_due_date: 1634101200000,
                physiological_due_date: 1634101200000,
                prepared: 1634101200000,
                records_view_due_date: 1634101200000,
                ssan: '***-**-7911',
                unit: '0063ARSSQ',
            },
            table: [
                {
                    acc: 'YES',
                    accomp: 3,
                    data_in_phase: 1634101200000,
                    data_last_accomp: 1634101200000,
                    due_data: 1634101200000,
                    ovedue: '',
                    pgm: 'GT',
                    qualification: '63ARS BOOM FTL C.2',
                    rem: 25,
                    req: 5,
                    rest_code: 'N',
                    task_id: 'AA01YM',
                    task_name: 'QUAL EVAL',
                    wvd: '',
                }
            ],
            createdAt: 1634101200000,
            updatedAt: 1634101200000,
        };
        super(seedData);
    }
}
exports.ItsMock = ItsMock;
