"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SarmCodesMock = void 0;
const sarm_codes_model_1 = require("./sarm-codes.model");
class SarmCodesMock extends sarm_codes_model_1.SarmCodes {
    constructor(data) {
        const seedData = {
            id: 'asdasdsDFRFSSDAsda',
            code: 'A',
            description: 'Aircraft Commander',
            squadronId: 'asdasdsDFRFSSDAsda',
        };
        super(seedData);
    }
}
exports.SarmCodesMock = SarmCodesMock;
