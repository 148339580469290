"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SquadronMock = void 0;
const squadron_model_1 = require("./squadron.model");
const point_of_contact_1 = require("./point-of-contact");
const aircrew_composition_1 = require("./aircrew-composition");
class SquadronMock extends squadron_model_1.Squadron {
    constructor(data) {
        const seedData = {
            name: 'ABC123',
            mds: 'C-130H',
            createdAt: 1597722305392,
            searchKeys: ['a', 'ab', 'abc', 'abc1'],
            pointOfContact: new point_of_contact_1.PointOfContact(),
            aircrewComposition: new aircrew_composition_1.AircrewComposition(),
            users: [],
            harmLocation: 'McDill AFB',
            isActive: true,
            timeZone: 'EST',
            enabledRating: false,
            pocName: ''
        };
        super(seedData);
    }
}
exports.SquadronMock = SquadronMock;
