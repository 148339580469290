"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventAircraftDetail = void 0;
const base_model_1 = require("../../base-model");
const event_aircraft_detail_schema_1 = require("./event-aircraft-detail.schema");
class EventAircraftDetail extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {};
    }
    getSchema() {
        return event_aircraft_detail_schema_1.eventAircraftDetailSchema;
    }
}
exports.EventAircraftDetail = EventAircraftDetail;
