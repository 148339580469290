import { BaseModel, DataPartial } from 'contracts';
import { DialogType, DialogSeverity } from '../../enums';
import { dialogConfigSchema } from './dialog-config.schema';
import { ObjectSchema } from 'yup';

export class DialogConfig extends BaseModel {
  public title!: string;
  public content!: string;
  public destroyed!: boolean;
  public opaqueBackground?: boolean;
  public canBeDismissed?: boolean;
  public type?: DialogType;
  public severity?: DialogSeverity;
  public timeout?: number;
  public id?: string;

  constructor(data?: DataPartial<DialogConfig>, skipValidation?: boolean) {
    super(data, skipValidation);
  }

  protected getDefaults(): DataPartial<BaseModel> {
    return {
      title: 'Title',
      content: 'Content placeholder',
      type: DialogType.JustText,
      timeout: 10000,
      destroyed: false,
      opaqueBackground: false,
      canBeDismissed: true,
      severity: DialogSeverity.Default,
    };
  }

  protected getSchema(): ObjectSchema<any> {
    return dialogConfigSchema;
  }
}
