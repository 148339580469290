import { Injectable, EventEmitter } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AddToaster, RemoveToaster, AddDialog, RemoveDialog } from './store-alert/actions/alert.actions';
import { v4 as uuid } from 'uuid';
import { ToasterEvents, ToasterType, DialogType, DialogEvents } from './core/enums';
import { delay, take } from 'rxjs/operators';
import { ToasterConfig } from './core/models';
import { selectToasterById, selectDialogById } from './store-alert/selectors/alert.selectors';
import { DataPartial } from 'contracts';
import { DialogConfig } from './core/models/dialog-config';
import { IDialog, IToaster } from './core/interfaces';
import { IApiException } from '../core/interfaces/api-exception.interface';

@Injectable({
  providedIn: 'root',
})
export class AlertSystemService {
  // List of emitters and their associated alert component ID
  private emittersAssociated: {
    associationID: string;
    eventEmitter: EventEmitter<ToasterEvents | DialogEvents>;
  }[] = [];

  constructor(private store: Store) {}

  /** * Toaster ***/
  public showToaster(partialToasterConfig: DataPartial<ToasterConfig>): IToaster {
    const toasterId = uuid();
    const eventEmitter = new EventEmitter<ToasterEvents>();
    const toasterConfig = new ToasterConfig({
      ...partialToasterConfig,
      id: toasterId,
      type: partialToasterConfig.type || ToasterType.Default,
      destroyed: false,
    });

    this.store.dispatch(AddToaster({ toaster: toasterConfig }));
    this.emittersAssociated.push({
      associationID: toasterId,
      eventEmitter,
    });

    // Create a timer for it to be removed
    let timeout: number;
    if (partialToasterConfig.timeout) {
      timeout = partialToasterConfig.timeout;
    } else {
      switch (partialToasterConfig.type) {
        case ToasterType.Success:
          timeout = 6000;
          break;
        case ToasterType.Error:
          timeout = 20000;
          break;
        case ToasterType.Warning:
          timeout = 10000;
          break;
        case ToasterType.Default:
          timeout = 6000;
          break;
        default:
          timeout = 6000;
          break;
      }
    }
    this.store.pipe(delay(timeout), select(selectToasterById(toasterId)), take(1)).subscribe({
      next: (toaster: ToasterConfig) => {
        this.removeToaster(toaster.id, ToasterEvents.ClosedByTimeout);
      },
    });

    const toasterObject: IToaster = {
      toasterConfig,
      eventEmitter,
      destroy: () => this.removeToaster(toasterId),
    };

    return toasterObject;
  }

  public removeToaster(toasterID: string, event: ToasterEvents = ToasterEvents.ClosedByClick) {
    this.store
      .pipe(select(selectToasterById(toasterID)))
      .pipe(take(1))
      .subscribe({
        next: (toaster: ToasterConfig) => {
          if (toaster) {
            this.store.dispatch(RemoveToaster({ toasterID }));
            const eventEmitter = this.getEmitterForID(toaster.id);
            if (eventEmitter) {
              eventEmitter.emit(event);
              this.clearEmitter(toaster.id);
            }
          }
        },
      });
  }

  /** * Dialog ***/

  public showDialog(partialDialogConfig: DataPartial<DialogConfig>): IDialog {
    const dialogId = uuid();
    const eventEmitter = new EventEmitter<DialogEvents>();
    const dialogConfig = new DialogConfig({
      ...partialDialogConfig,
      id: dialogId,
      type: partialDialogConfig.type || DialogType.JustText,
      destroyed: false,
    });

    this.store.dispatch(AddDialog({ dialog: dialogConfig }));
    this.emittersAssociated.push({
      associationID: dialogId,
      eventEmitter,
    });

    // Create a timer for it to be removed
    if (partialDialogConfig.timeout) {
      this.store.pipe(delay(partialDialogConfig.timeout), select(selectDialogById(dialogId)), take(1)).subscribe({
        next: (dialog: DialogConfig) => {
          this.removeToaster(dialog.id, ToasterEvents.ClosedByTimeout);
        },
      });
    }

    const dialogObject: IDialog = {
      dialogConfig,
      eventEmitter,
      destroy: () => this.removeDialog(dialogId),
    };

    return dialogObject;
  }

  public removeDialog(dialogID: string, event: DialogEvents = DialogEvents.ClosedByClick) {
    this.store
      .pipe(select(selectDialogById(dialogID)))
      .pipe(take(1))
      .subscribe({
        next: (dialog: DialogConfig) => {
          if (dialog) {
            this.store.dispatch(RemoveDialog({ dialogID }));
            const emitter = this.getEmitterForID(dialog.id);
            if (emitter) {
              emitter.emit(event);
              this.clearEmitter(dialog.id);
            }
          }
        },
      });
  }

  public fireEmitterEvent(event: DialogEvents | ToasterEvents, associationID: string) {
    const emitter = this.getEmitterForID(associationID);

    if (emitter) {
      emitter.emit(event);
    }
  }

  public showApiException(exception: IApiException, alternativeTitle = '', alternativeDescription = '') {
    this.showToaster({
      title: exception?.error?.errors[0]?.name || alternativeTitle,
      content: exception?.error?.errors[0]?.messages[0] || alternativeDescription,
      type: ToasterType.Error,
    });
  }

  /**
   * Iterates through this.emittersAssociated and return the event emitter
   * for an alert/dialog/toaster
   * If second argument is true, the event emitter will be marked as null so it can't be used again
   */
  private getEmitterForID(relationID: string, markAsNull = false): EventEmitter<ToasterEvents | DialogEvents> | null {
    for (const emitterRelation of this.emittersAssociated) {
      if (relationID === emitterRelation.associationID) {
        if (markAsNull) {
          emitterRelation.eventEmitter = null;
        }

        return emitterRelation.eventEmitter;
      }
    }

    return null;
  }

  private clearEmitter(relationID: string) {
    this.getEmitterForID(relationID, true);
  }
}
