"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.paginationAndFilterQuerySchema = void 0;
const yup = require("yup");
const enums_1 = require("../../enums");
const utilities_1 = require("../../utilities");
exports.paginationAndFilterQuerySchema = yup.object().shape({
    search: yup.string().notRequired(),
    orderBy: yup
        .string()
        .oneOf([
        ...(0, utilities_1.getEnumValues)(enums_1.SquadronSortKeysType),
        ...(0, utilities_1.getEnumValues)(enums_1.AircrewSortKeysType),
        ...(0, utilities_1.getEnumValues)(enums_1.AircrewTypeSortKeysType),
    ])
        .notRequired(),
    orderByDirection: yup.string().oneOf((0, utilities_1.getEnumValues)(enums_1.SortFilterDirectionType)).notRequired(),
    infiniteScrollRefId: yup.string().notRequired(),
    limitResults: yup.number().positive().integer().notRequired().min(1).nullable(),
    offset: yup.number().notRequired().nullable(),
});
