"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransitionEntity = void 0;
const base_model_1 = require("../../../../base-model");
const transition_entity_schedule_1 = require("./transition-entity.schedule");
class TransitionEntity extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {};
    }
    getSchema() {
        return transition_entity_schedule_1.transitionEntitySchema;
    }
}
exports.TransitionEntity = TransitionEntity;
