import { createAction, props } from '@ngrx/store';
import { Squadron, PaginationAndFilterQuery, User, AircrewType } from 'contracts';

// Squadrons

export const GetSquadrons = createAction('[Admin] Update list of squadrons to be managed');
export const UpdateSquadrons = createAction(
  '[Admin] Set list of squadrons to be managed',
  props<{ squadrons: Squadron[] }>()
);
export const UpdateSquadron = createAction(
  '[Admin] Set an squadron in the list of squadrons to be managed',
  props<{ squadron: Squadron }>()
);
export const ManagedSquadronsLoaded = createAction('[Admin] Managed squadrons loaded success');

export const UpdatePaginationAndFilterQuery = createAction(
  '[Admin] Set paginationAndFilterQuery',
  props<{ paginationAndFilterQuery: PaginationAndFilterQuery }>()
);

export const SetAllUsersInStore = createAction('[Admin] Set all users', props<{ users: User[] }>());
export const UpdateUser = createAction('[Admin] Edit user', props<{ user: User }>());
export const DeleteUser = createAction('[Admin] Delete user', props<{ userId: string }>());

/* AircrewTypes */
export const SetAircrewTypesInStore = createAction(
  '[Admin] Set all aircrewTypes',
  props<{ aircrewTypes: AircrewType[] }>()
);

export const ResetFilters = createAction('[Admin] Reset filter object on logout');
export const DeleteAircrewType = createAction('[Admin] Delete aircrewType', props<{ aircrewTypeId: string }>());
