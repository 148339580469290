"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AircraftStatusType = void 0;
var AircraftStatusType;
(function (AircraftStatusType) {
    AircraftStatusType["FMC"] = "FMC";
    AircraftStatusType["PMC"] = "PMC";
    AircraftStatusType["NMC"] = "NMC";
    AircraftStatusType["EMPTY"] = "";
})(AircraftStatusType = exports.AircraftStatusType || (exports.AircraftStatusType = {}));
