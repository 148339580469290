"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserSupportRequest = void 0;
const user_support_request_schema_1 = require("./user-support-request.schema");
const base_model_1 = require("../base-model");
class UserSupportRequest extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {
            user: null,
            version: null,
            date: null,
            os: null,
            browser: null,
            problemDescription: null,
        };
    }
    getSchema() {
        return user_support_request_schema_1.userSupportRequestSchema;
    }
}
exports.UserSupportRequest = UserSupportRequest;
