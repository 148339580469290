"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AllUsersAPI = void 0;
const user_1 = require("../user");
class AllUsersAPI extends user_1.User {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
}
exports.AllUsersAPI = AllUsersAPI;
