"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventMissionProfile = void 0;
const base_model_1 = require("../../base-model");
const event_mission_profile_schema_1 = require("./event-mission-profile.schema");
class EventMissionProfile extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {};
    }
    getSchema() {
        return event_mission_profile_schema_1.eventMissionProfileSchema;
    }
}
exports.EventMissionProfile = EventMissionProfile;
