"use strict";
/**
 * User permission roles are assigned a numeric value which can be used to compare and check permissions
 *
 * SuperDevAdmin = 5,
 * SuperAdmin = 4,
 * ChiefAdmin = 3,
 * Admin = 2,
 * AircrewMember = 1,
 * None = 0 This is a guest or a user which has not been authenticated
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserPermissionValue = void 0;
const enums_1 = require("../enums");
const getUserPermissionValue = (roleType) => {
    switch (roleType) {
        case enums_1.RoleType.SuperDevAdmin:
            return 5;
        case enums_1.RoleType.SuperAdmin:
            return 4;
        case enums_1.RoleType.ChiefAdmin:
            return 3;
        case enums_1.RoleType.Admin:
            return 2;
        case enums_1.RoleType.AircrewMember:
            return 1;
    }
    return 0;
};
exports.getUserPermissionValue = getUserPermissionValue;
