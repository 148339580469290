"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.areUsersInSameSquadron = void 0;
/*
 * In order to maintain the app secure, this method should be called as follows:
 *  In Frontend, we can pass the local modifierUser and toModifyUser, since it does not matter as it can be altered
 *  In Backend, we should use the @CurrentProflytUser() as the modifier, and request the modifiedUser directly
 *  from the database using the given ID, DON'T use the provided users from frontend directly.
 */
/**
 * Checks whether 2 users share a common squadron
 */
const areUsersInSameSquadron = (modifierUser, userToModify) => {
    var _a;
    for (const modifierUserSquadron of modifierUser.squadronIds) {
        if ((_a = userToModify.squadronIds) === null || _a === void 0 ? void 0 : _a.includes(modifierUserSquadron)) {
            return true;
        }
    }
    return false;
};
exports.areUsersInSameSquadron = areUsersInSameSquadron;
