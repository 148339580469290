"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventsRequest = void 0;
const events_request_schema_1 = require("./events-request.schema");
const base_model_1 = require("../base-model");
class EventsRequest extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {};
    }
    getSchema() {
        return events_request_schema_1.eventsRequestSchema;
    }
}
exports.EventsRequest = EventsRequest;
