"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Af1522 = void 0;
const af1522_schema_1 = require("./af1522.schema");
const official_form_base_model_1 = require("../official-form-base-model");
class Af1522 extends official_form_base_model_1.OfficialFormBaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {
            trainingDataRows: [],
        };
    }
    getSchema() {
        return af1522_schema_1.af1522Schema;
    }
}
exports.Af1522 = Af1522;
