"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccomplishmentReportNotes = void 0;
const accomplishment_report_notes_schema_1 = require("./accomplishment-report-notes.schema");
const base_model_1 = require("../base-model");
class AccomplishmentReportNotes extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {
            userName: null,
            reportId: null,
            comment: null,
            createdAt: null,
        };
    }
    getSchema() {
        return accomplishment_report_notes_schema_1.accomplishmentReportNotesSchema;
    }
}
exports.AccomplishmentReportNotes = AccomplishmentReportNotes;
