"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.arctEntitySchema = void 0;
const yup = require("yup");
const utilities_1 = require("../../../../../utilities");
const enums_1 = require("../../../../../enums");
exports.arctEntitySchema = yup.object().shape({
    id: yup.string().notRequired(),
    arTrack: yup.string().notRequired(),
    arct: yup
        .string()
        .nullable()
        .test('validate-duration', 'Show time should be between 00:00 and 23:00', utilities_1.validateOtherTimes)
        .notRequired(),
    blockAltitude: yup.string().notRequired(),
    rendevousType: yup.string().nullable().oneOf((0, utilities_1.getEnumValues)(enums_1.RendevousType)).notRequired(),
    timeOnTrack: yup.string().notRequired(),
    offloadAmount: yup.number().notRequired(),
    onloadAmount: yup.number().notRequired(),
    receiverType: yup.string().notRequired(),
    receiverNumber: yup.number().notRequired(),
    receiverCallSign: yup.array().of(yup.string()).notRequired(),
});
