"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasicUserMock = void 0;
const basic_user_model_1 = require("./basic-user.model");
class BasicUserMock extends basic_user_model_1.BasicUser {
    constructor(data) {
        const seedData = Object.assign({ id: '^&STEVC*SCU', name: 'Jessie', email: 'shajessie@hotmail.com' }, data);
        super(seedData);
    }
}
exports.BasicUserMock = BasicUserMock;
