import { createAction, props } from '@ngrx/store';
import { ToasterConfig } from '../../core/models';
import { DialogConfig } from '../../core/models/dialog-config';

/** * Toaster ***/

export const ClearAllToasters = createAction('[Alert] Clear all active toasters');

export const AddToaster = createAction('[Alert] Add toaster', props<{ toaster: ToasterConfig }>());

export const RemoveToaster = createAction('[Alert] Remove toaster by id', props<{ toasterID: string }>());

/** * Dialog ***/

export const ClearAllDialogs = createAction('[Alert] Clear all active dialogs');

export const AddDialog = createAction('[Alert] Add dialog', props<{ dialog: DialogConfig }>());

export const RemoveDialog = createAction('[Alert] Remove dialog by id', props<{ dialogID: string }>());
