import { Component, Inject, NgZone, OnDestroy, OnInit, Renderer2, RendererStyleFlags2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { Platform } from '@ionic/angular';
import { from, fromEvent, Subject } from 'rxjs';
import { NavigationHistoryService } from './core/services/navigation-history.service';
import { take, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { selectActiveDialogs } from './alert-system/store-alert/selectors/alert.selectors';
import { DialogConfig } from './alert-system/core/models/dialog-config/dialog-config.model';
import { DialogType } from './alert-system/core/enums/dialog-type.enum';
import { UpdateAppVersionService } from './core/services/update-app-version.service';
import { AlertSystemService } from './alert-system/alert-system.service';
// Capacitor
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { AuthProviderHelper } from './auth/providers/core/auth-provider.helper';
import { AuthProvider } from './auth/providers/auth.provider';
import { AuthProviderType } from 'contracts';

@Component({
  selector: 'pro-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private authProvider: AuthProvider;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private platform: Platform,
    private zone: NgZone,
    private renderer: Renderer2,
    private store: Store,
    private alertSystemService: AlertSystemService,
    private updateAppVersionService: UpdateAppVersionService,
    private navigationHistoryService: NavigationHistoryService,
    private authProviderHelper: AuthProviderHelper
  ) {}

  public ngOnInit() {
    this.initAuthProvider();
    this.authSub();
    // The following will allow the keyboard to close when click outside an input in iOS
    // We add it here in order to take effect on All Modules
    const firstChild: HTMLElement = this.document.firstElementChild as HTMLElement;

    if (firstChild && firstChild.tabIndex) {
      firstChild.tabIndex = 1;
    }

    // Universal links
    this.appUniversalLink();

    // Device specific modifications
    this.applyDeviceModifications();

    this.applicationReadyState();
    this.applicationPauseSub();
    this.applicationResumeSub();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Needed to open the app when user use external links
   * Ex: when user is add to an event he receive an email with a link to the ProfFlyt app
   * and if we are in mobile app this functionality allow us open the link and go inside mobile app and not load
   * web browser to open link.
   */
  private appUniversalLink() {
    App.addListener('appUrlOpen', (data: any) => {
      this.zone.run(() => {
        let slug = '';
        // Replace is used to replace only the first occurrence, then split buy this value replaced to get slug
        // Example url: https://dev-proflyt.web.app/tabs/tab2 or https://www.proflytblue.com/tabs/tab2
        // slug = /tabs/tab2
        if (environment.production) {
          // For production environment app url ex: https://www.proflytblue.com/tabs/tab2
          slug = data.url.replace('.com', '***').split('***').pop();
        } else {
          // For development environment app url ex: https://dev-proflyt.web.app/tabs/tab2
          // slug = data.url.replace('.app', '***').split('***').pop();
          slug = data.url.replace('.com', '***').split('***').pop();
        }

        if (slug) {
          this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing.
      });
    });
  }

  /**
   * This will help when styling for specific devices,  os' or bundled apps
   * Example: There is an ios specific bug in styling, we can use
   * body.ios my-target-class {}
   * To address that issue
   */
  private applyDeviceModifications() {
    const body: HTMLElement = this.document.body;

    if (this.platform.is('android')) {
      this.applyAndroidSpecificAdjustments();
      this.handleAndroidBackButton();

      return;
    }

    if (this.platform.is('ios')) {
      this.applyIosSpecificAdjustments();

      return;
    }

    if (this.platform.is('capacitor')) {
      body.classList.add('is-app');

      return;
    }
  }

  private applyIosSpecificAdjustments() {
    const body: HTMLElement = this.document.body;

    body.classList.add('is-ios');
    body.classList.add('is-device');
  }

  private applyAndroidSpecificAdjustments() {
    const body: HTMLElement = this.document.body;

    body.classList.add('is-android');
    body.classList.add('is-device');

    /*
      This part of the code adapts the height of the body to the height visible area of the screen and
        to avoid overlapping the android virtual buttons with our content 
    */
    const virtualButtonsHeight: number = screen.height - window.innerHeight;
    const flags = RendererStyleFlags2.Important;

    // Overwrite min-height  from _base
    this.renderer.setStyle(body, 'min-height', `calc(100vh - ${virtualButtonsHeight}px)`, flags);
  }

  private handleAndroidBackButton() {
    fromEvent(document, 'backbutton')
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: Event) => {
        this.zone.run(() => {
          event.stopPropagation();
          this.navigationHistoryService.goBack();
        });
      });
  }

  private applicationReadyState() {
    if ((this.platform.is('ios') || this.platform.is('android')) && !this.platform.is('mobileweb')) {
      from(this.platform.ready())
        .pipe(take(1))
        .subscribe({
          next: () => {
            if (environment.production) {
              this.updateAppVersionService.checkForUpdate();
            }

            if (Capacitor.isPluginAvailable('SplashScreen')) {
              SplashScreen.hide();
            }
          },
        });
    }
  }

  private applicationPauseSub() {
    if ((this.platform.is('ios') || this.platform.is('android')) && !this.platform.is('mobileweb')) {
      from(this.platform.pause)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            if (environment.production) {
              this.deleteUpdateAppDialog();
            }
          },
        });
    }
  }

  private applicationResumeSub() {
    if ((this.platform.is('ios') || this.platform.is('android')) && !this.platform.is('mobileweb')) {
      from(this.platform.resume)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            if (environment.production) {
              this.updateAppVersionService.checkForUpdate();
            }
          },
        });
    }
  }

  // Delete update app dialog
  private deleteUpdateAppDialog() {
    this.store.pipe(select(selectActiveDialogs), take(1)).subscribe({
      next: (dialogs: DialogConfig[]) => {
        for (const dialog of dialogs) {
          if (dialog.type === DialogType.Update) {
            this.alertSystemService.removeDialog(dialog.id);
          }
        }
      },
    });
  }

  private initAuthProvider() {
    this.authProvider = this.authProviderHelper.initAuthProvider();
  }

  private authSub() {
    if (environment.authProvider === AuthProviderType.keycloak) {
      this.authProvider.authEvents();
    }
  }
}
