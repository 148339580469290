"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OfficialFormBaseModel = void 0;
const base_model_1 = require("../../base-model");
class OfficialFormBaseModel extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {
            isLocked: false,
        };
    }
}
exports.OfficialFormBaseModel = OfficialFormBaseModel;
