"use strict";
/**
 * Checks whether a user can control/edit/manipulate another
 * Logic checks in this function:
 * ChiefAdmin CAN modify ChiefAdmin, Admin, AircrewMember IF in same squadron
 * Admin CAN modify Admin, AircrewMember IF in same squadron
 * Aircrew Cannot modify anyone
 * SuperAdmins and developers can modify at will
 *
 * In order to maintain the app secure, this method should be called as follows:
 *  In Frontend, we can pass the local modifierUser and toModifyUser, since it does not matter as it can be altered
 *  In Backend, we should use the @CurrentProflytUser() as the modifier, and request the modifiedUser directly
 *  from the database using the given ID, DON'T use the provided users from frontend directly.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.canUserUpdateAnotherRole = void 0;
const enums_1 = require("../enums");
const are_users_in_same_squadron_function_1 = require("./are-users-in-same-squadron.function");
const get_user_permission_value_function_1 = require("./get-user-permission-value.function");
const canUserUpdateAnotherRole = (modifierUser, userToModify, shouldTheyBeInSameSquadron = true) => {
    // Super Admins and super admin developers can manipulate anyone, even each other
    if (modifierUser.role === enums_1.RoleType.SuperAdmin || modifierUser.role === enums_1.RoleType.SuperDevAdmin) {
        return true;
    }
    const modifierRoleValue = (0, get_user_permission_value_function_1.getUserPermissionValue)(modifierUser.role);
    const toModifyRoleValue = (0, get_user_permission_value_function_1.getUserPermissionValue)(userToModify.role);
    // If the role is higher: (and not an aircrew)
    if (modifierRoleValue >= toModifyRoleValue && modifierUser.role !== enums_1.RoleType.AircrewMember) {
        // Checks if they belong to the same squadron, in case it is a requirement
        if (shouldTheyBeInSameSquadron) {
            const areUsersInSameSquad = (0, are_users_in_same_squadron_function_1.areUsersInSameSquadron)(modifierUser, userToModify);
            return areUsersInSameSquad;
        }
        return true;
    }
    return false;
};
exports.canUserUpdateAnotherRole = canUserUpdateAnotherRole;
