"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventComment = void 0;
const event_comment_schema_1 = require("./event-comment.schema");
const base_model_1 = require("../base-model");
class EventComment extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {
            user: null,
            eventId: null,
            comment: null,
            createdAt: null,
        };
    }
    getSchema() {
        return event_comment_schema_1.eventCommentSchema;
    }
}
exports.EventComment = EventComment;
