import { Injectable } from "@angular/core";
import { IAuthStrategy } from "../interfaces";
import { User, RoleType, AuthProviderType, getUserPermissionValue } from "contracts";
import { Observable, of, switchMap, take } from "rxjs";
import { Store, select } from "@ngrx/store";
import { Router } from "@angular/router";
import { AuthApiService } from "../../services/auth-api.service";
import { StorageService } from "src/app/shared/services/storage.service";
import { selectAuthState } from "../../store-auth/selectors/auth.selectors";
import { IAuthState } from "../../store-auth/reducers/auth-state.reducer";
import { SetAuthStatus } from "../../store-auth/actions/auth.actions";

@Injectable({
  providedIn: 'root',
})
export class GameWardenStrategy implements IAuthStrategy {
  public name: AuthProviderType = AuthProviderType.gamewarden;

  constructor(
    private store: Store,
    private router: Router,
    private authApiService: AuthApiService,
    private storageService: StorageService
  ) {}

  logout(shouldNotRedirect?: boolean, areTokensAlreadyRevoked?: boolean): void {
    // TODO
  }
  isAuth(): Observable<boolean> {
    // TODO
    return of(true);
  }
  public getProflytUser(): Observable<User> {
    return this.authApiService.getCurrentUserInfo().pipe(
      switchMap((user: User) => {
        return this.setProflytUserInStore(user);
      })
    );
  }
  resetPassword(userEmail?: string): Observable<void> {
    // TODO
    return of(null);
  }
  public verifyUserPermissions(activeUserRoleType: RoleType, roleType: RoleType): boolean {
    const activeUserRole: number = getUserPermissionValue(activeUserRoleType);
    const role: number = getUserPermissionValue(roleType);

    return activeUserRole >= role;
  }
  authEvents(): void {
    // TODO
  }
  signIn?(email: string, password: string): Observable<User> {
    // TODO
    return of(null);
  }

  private setProflytUserInStore(user: User): Observable<User> {
    return this.store.pipe(
      select(selectAuthState),
      switchMap((authState: IAuthState) => {
        if (user && authState.user) {
          // Logged in, no need for anything
          return of(authState.user);
        }

        if (!user) {
          // Not logged in
          if (authState.isLoggedIn) {
            this.store.dispatch(
              SetAuthStatus({
                isLoggedIn: false,
                user: null,
              })
            );
          }

          return of(null);
        }

        // Logged in, and no state in store
        return this.authApiService.getCurrentUserInfo().pipe(
          take(1),
          switchMap((userInfo: User) => {
            const newUser = new User({ ...userInfo });
            this.store.dispatch(
              SetAuthStatus({
                isLoggedIn: true,
                user: new User(userInfo),
              })
            );

            return of(newUser);
          })
        );
      }),
      take(1)
    );
  }
}