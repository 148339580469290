"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userSchema = void 0;
const yup = require("yup");
const aircrew_type_1 = require("../aircrew-type");
const enums_1 = require("../../enums");
const user_configuration_1 = require("./user-configuration");
const user_config_time_zone_enum_1 = require("../../enums/user-config-time-zone.enum");
const utilities_1 = require("../../utilities");
const validators_1 = require("../../validators");
exports.userSchema = yup.object().shape({
    _id: yup.string(),
    id: yup.string(),
    squadronIds: yup.array().of(yup.string()).notRequired(),
    mainSquadronId: yup.string().required(),
    mainSquadronName: yup.string().notRequired(),
    firstName: yup.string().notRequired(),
    lastName: yup.string().notRequired(),
    email: yup.string().email().required(),
    password: (0, utilities_1.regexStringSchema)(validators_1.pwdChars8letters1numbers1, { required: false }),
    joinedAt: yup.number().min(1577851200000).notRequired(),
    invitedAt: yup.number().required(),
    aircrewPosition: aircrew_type_1.aircrewTypeSchema,
    phone: yup.number().positive().integer().notRequired().nullable(),
    ftl: yup
        .string()
        .oneOf([...(0, utilities_1.getEnumValues)(enums_1.FtlType), null])
        .notRequired()
        .nullable(),
    mds: yup.string().oneOf((0, utilities_1.getEnumValues)(enums_1.MdsType)).notRequired(),
    role: yup.string().oneOf((0, utilities_1.getEnumValues)(enums_1.RoleType)).notRequired(),
    specificationRoles: yup.array().of(yup.string()).notRequired(),
    // Metadata
    metadata: yup.object().notRequired().shape({
        note: yup.string().notRequired(),
    }),
    // Flags
    // enableApp flag is for allowing the user to use the mobile app to join events. Is ON by default
    enableApp: yup.boolean().notRequired(),
    mct: yup.boolean().notRequired(),
    nmr: yup.boolean().notRequired(),
    searchKeys: yup.array().of(yup.string()).notRequired(),
    sortByAircrewPositionType: yup.string().notRequired(),
    timezone: yup.string().notRequired(),
    isPublicUser: yup.boolean().notRequired(),
    // Allow user to unlock mobile app with biometrics params(finger print and face id)
    isBiometricAuthEnabled: yup.boolean().notRequired(),
    devicesAcceptingPushNotifications: yup.array().of(yup.string()).notRequired(),
    notificationConfiguration: user_configuration_1.userNotificationConfigurationSchema.notRequired(),
    defaultTimezone: yup.string().oneOf((0, utilities_1.getEnumValues)(user_config_time_zone_enum_1.UserConfigTimeZoneType)).notRequired(),
    isRatingReply: yup.boolean().notRequired(),
    remindMeLater: yup.number().positive().notRequired(),
});
