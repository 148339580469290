"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventIdMock = void 0;
const event_id_model_1 = require("./event-id.model");
const enums_1 = require("../../enums");
class EventIdMock extends event_id_model_1.EventId {
    constructor(data) {
        const seedData = {
            name: 'sim2',
            type: enums_1.CalendarEventTypes.SIM,
            id: 'qw3sd45fvgtg',
            squadronId: 'squadron-id',
            duration: '23:30',
            notes: 'test note',
        };
        super(seedData);
    }
}
exports.EventIdMock = EventIdMock;
