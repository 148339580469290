"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalendarEvent = void 0;
const calendar_event_schema_1 = require("./calendar-event.schema");
const base_model_1 = require("../base-model");
class CalendarEvent extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {
            isFull: false,
            requestCount: 0,
            isManualSorting: false,
        };
    }
    getSchema() {
        return calendar_event_schema_1.calendarEventSchema;
    }
}
exports.CalendarEvent = CalendarEvent;
