"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PointOfContactMock = void 0;
const point_of_contact_model_1 = require("./point-of-contact.model");
const enums_1 = require("../../../enums");
class PointOfContactMock extends point_of_contact_model_1.PointOfContact {
    constructor(data) {
        const seedData = Object.assign({ firstName: 'John', lastName: 'Smith', email: 'shajessie@hotmail.com', phone: 5555555566, aircrewType: enums_1.AircrewPositionType.AerialGunner, dutyPosition: 'EH' }, data);
        super(seedData);
    }
}
exports.PointOfContactMock = PointOfContactMock;
