"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calendarEventSchema = void 0;
const yup = require("yup");
const enums_1 = require("../../enums");
const user_event_relation_1 = require("../user-event-relation");
const event_formation_1 = require("./event-formation");
const utilities_1 = require("../../utilities");
const event_aircraft_detail_1 = require("./event-aircraft-detail");
const event_mission_detail_1 = require("./event-mission-detail");
const event_mission_profile_1 = require("./event-mission-profile");
exports.calendarEventSchema = yup.object().shape({
    id: yup.string().notRequired(),
    name: yup.string().required().required(),
    type: yup.string().required().oneOf((0, utilities_1.getEnumValues)(enums_1.CalendarEventTypes)).required(),
    startDate: yup.number().min(1577851200000).max(2524622400000).required(),
    endDate: yup
        .number()
        .min(1577851200000)
        .max(2524622400000)
        .test('test-dates', 'End time should be after start time', utilities_1.compareDates)
        .required(),
    squadronId: yup.string().required(),
    usersEnrolled: yup.array().of(user_event_relation_1.userEventRelationSchema).notRequired(),
    isFull: yup.boolean().required(),
    note: yup.string().notRequired(),
    requestCount: yup.number().min(0).required(),
    isEventClosedByNotificationBell: yup.boolean().notRequired(),
    eventFormation: event_formation_1.eventFormationSchema.notRequired(),
    color: yup.string().notRequired(),
    aircraftDetail: event_aircraft_detail_1.eventAircraftDetailSchema.notRequired(),
    missionDetail: event_mission_detail_1.eventMissionDetailSchema.notRequired(),
    missionProfile: event_mission_profile_1.eventMissionProfileSchema.notRequired(),
    isManualSorting: yup.boolean().notRequired().default(false),
    mainEventId: yup.string().notRequired(),
});
